<template>
  <v-timeline side="end" truncate-line="none">
    <v-timeline-item
        v-for="(timelineItem, index) in daysParsedTxs"
        :dot-color="timelineItem.tx.confirmed ? '' : 'amber'"
        :key="index"
        :class="!!timelineItem.period?'pt-30' : ''"
        class="pointer no-select"
        size="x-small"
        @click="()=>{if($route.path.startsWith('/btc')){$emit('fetch-detail', timelineItem.tx)}}"
    >
      <template v-slot:opposite>
        <!-- DATE -->
        <div class="text-align-right">
          <p class="text-subtitle-1" style="line-height: 20px">{{ timelineItem.period }}</p>
          <p v-if="!isNaN(timelineItem.tx.date.getTime()) && (!daysParsedTxs?.[index-1] || daysParsedTxs[index-1].tx.date.toLocaleTimeString() !== timelineItem.tx.date.toLocaleTimeString())" class="text-caption opacity-60">{{ timelineItem.tx.date.toLocaleTimeString() }}</p>
          <p v-else-if="isNaN(timelineItem?.tx?.date?.getTime()) && (!daysParsedTxs?.[index-1] || !isNaN(daysParsedTxs[index-1].tx.date.getTime()))" class="text-caption opacity-60">Just now - pending</p>
        </div>
      </template>
      <template v-slot:default>
        <!-- AMOUNTS -->
        <div class="pb-5">
        <Tooltip :text="timelineItem.tx.txId.slice(0,24) +'...'">
          <div>
            <p v-if="timelineItem.tx.value !== 0 || !timelineItem.tx.details"
                class="text-md-h6 text-sm-body-1 text-xs-caption text-body-2 opacity-80"
                style="min-width: 80px">
            <span :class="timelineItem.tx.value >= 0 ? 'outputs-amount' : 'inputs-amount'">
              {{ mainFiat ? timelineItem.tx.fiatVal : timelineItem.tx.cryptoVal }}
            </span>
            </p>
            <p v-if="timelineItem.tx.value !== 0 || !timelineItem.tx.details" class="text-caption opacity-60">
              {{ mainFiat ? timelineItem.tx.cryptoVal : timelineItem.tx.fiatVal }}</p>
            <p v-if="timelineItem.tx.value === 0 && !!timelineItem.tx.details"
                class="text-sm-body-1 text-xs-caption text-body-2 opacity-80"
                style="min-width: 80px">
              {{ mainFiat ? timelineItem.tx.fiatVal : timelineItem.tx.cryptoVal }}
            </p>
            <p v-if="timelineItem.tx.value === 0 && !!timelineItem.tx.details"
                class="text-caption opacity-60">{{ timelineItem.tx.details }}</p>
          </div>
        </Tooltip>
      </div>
      </template>
    </v-timeline-item>
    <v-timeline-item
        v-if="hasNonLoaded"
        class="pointer pt-30"
        :class="loading ? 'disabled':''"
        :dot-color="loading ? 'grey' : 'orange'"
        fill-dot
        :icon="icons.reload"
        @click="clickFetchMore"
    >
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import {mdiReload} from "@mdi/js";
import Tooltip from "@/components/Tooltip";

export default {
  name: "TxsTimeline",
  components: {Tooltip},
  props: {
    loading: Boolean,
    hasNonLoaded: Boolean,
    mainFiat: Boolean,
    daysParsedTxs: Array,
  },
  data() {
    return {
      icons: {
        reload: mdiReload,
      }
    }
  },
  computed: {

  },
  methods: {
    clickFetchMore() {
      this.$emit('fetchPagedTxs', true)
    },
  },
  mounted() {

  },
}
</script>

<style scoped>

</style>