<template>
  <template>
    <v-overlay class="pulse" v-model="active">
      <v-dialog
          content-class="pulse"
          v-model="active"
          persistent
      >
      </v-dialog>
    </v-overlay>
  </template>
</template>

<script>
export default {
  name: "PulseContainer",
  props: {
    active: { type: Boolean, default: true }
  }
}
</script>

<style lang="scss">
.pulse {
  .v-overlay__scrim{
    background-color: rgba(#222, 20%);
    animation-name: color-pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }

  @keyframes color-pulse {
    0% { background-color: rgba(#222, 20%); }
    50% { background-color: darken(rgba(150, 150, 150, 0.2),20%); }
    100% { background-color: rgba(#222, 20%); }
  }
}
</style>