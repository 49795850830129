import Utils from "@/assets/js/Utils";

class API {
	constructor() {
		this.DEBUG = false;
		this.DEBUG_PRINT_RESPONSES = false;

		this.CONTENT_TYPE = "application/x-www-form-urlencoded;charset=UTF-8";

		this.LAMBDA_ENDPOINT = "https://ghn8kvngyb.execute-api.eu-south-1.amazonaws.com/browse/";

		this.QR_ENDPOINT = this.LAMBDA_ENDPOINT+"getQr/";

		this.OPEN_NODE_ENDPOINTS = {
			GET_CHARGE: this.LAMBDA_ENDPOINT + "getCharge/",
			GET_PRIV_KEY: this.LAMBDA_ENDPOINT + "getPrivKey/",
		};
		this.BTC_ENDPOINTS = {
			GET_ADDRESS: this.LAMBDA_ENDPOINT + "getBtcAddress/",
			GET_TXS: this.LAMBDA_ENDPOINT + "getBtcAddressTxs/",
			GET_TXS_CHAIN: this.LAMBDA_ENDPOINT + "getBtcAddressTxsChain/",
			GET_TX: this.LAMBDA_ENDPOINT + "getBtcTx/",
		};
		this.SOL_ENDPOINTS = {
			GET_ACCOUNT: this.LAMBDA_ENDPOINT + "getSolAccount/",
			GET_ACCOUNT_TXS: this.LAMBDA_ENDPOINT + "getSolAccountTxs/",
			GET_ACCOUNT_STAKE_REWARDS: this.LAMBDA_ENDPOINT + "getSolAccountStakeRewards/",
			GET_ACCOUNT_STAKE_ACCOUNTS: this.LAMBDA_ENDPOINT + "getSolAccountStakeAccounts/",
		};
	}

	static init() {
		if (API.instance) {
			return API.instance;
		}
		API.instance = new API();
		return API.instance;
	}

	handleResponseParsing(response, callbackSuccess, callbackError) {
		if (this.DEBUG) {
			response.text().then((text) => {
				console.log(text);
			});
		}

		try {
			response
				.json()
				.then((json) => {
					if (this.DEBUG_PRINT_RESPONSES) {
						Utils.coloredLog("DEBUG PRINT RESPONSES:");
						console.log("", json);
					}
					callbackSuccess(json);
				})
				.catch((error) => {
					this.handleResponseException(error, callbackError);
				});
		} catch (e) {
			this.handleResponseException(e, callbackError);
		}
	}
	handleResponseException(error, callbackError) {
		Utils.coloredLog("Fetch error:");
		console.log("", error);
		if (callbackError) {
			callbackError(null);
		}
	}
	get(url, data, callback, callbackError = null) {
		let finalUrl = this.setParams(url, data);
		try {
			fetch(finalUrl, {
				method: "GET",
				cache: "no-store",
				headers: {"Content-type": this.CONTENT_TYPE},
			}).then((response) => {
				return this.handleResponseParsing(response, callback, callbackError);
			});
		} catch (e) {
			this.handleResponseException(e, callbackError);
		}
	}
	post(url, data, callback, callbackError = null) {
		const formBody = this.joinUrlParams(data);
		fetch(url, {
			method: "POST",
			body: formBody,
			cache: "no-store",
			headers: {"Content-type": this.CONTENT_TYPE},
		}).then((response) => {
			return this.handleResponseParsing(response, callback, callbackError);
		});
	}
	joinUrlParams(params) {
		if (params) {
			return Object.keys(params)
				.map(
					(key) =>
						encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
				)
				.join("&");
		} else {
			return "";
		}
	}
	setParams(url, data) {
		if (data && Object.keys(data).length > 0) {
			const params = this.joinUrlParams(data);
			url += "?" + params;
		}

		return url;
	}

	getCharge(address, uid, callback) {
		this.get(this.OPEN_NODE_ENDPOINTS.GET_CHARGE + address, {uid: uid}, callback);
	}
	getPrivateKey(address, uid, chargeId, callback) {
		this.get(this.OPEN_NODE_ENDPOINTS.GET_PRIV_KEY + address, {uid: uid, chargeId: chargeId}, callback);
	}

	getQr(text, callback) {
		this.get(this.QR_ENDPOINT + encodeURIComponent(text), null, callback);
	}

	getBtcAddress(address, callback) {
		this.get(this.BTC_ENDPOINTS.GET_ADDRESS + address, null, callback);
	}
	getBtcAddressTxs(address, callback) {
		this.get(this.BTC_ENDPOINTS.GET_TXS + address, null, callback);
	}
	getBtcAddressTxsPaged(address, last_seen_txid, callback) {
		this.get(this.BTC_ENDPOINTS.GET_TXS_CHAIN + address, {last_seen_txid: last_seen_txid}, callback);
	}
	getBtcTx(txId, callback) {
		this.get(this.BTC_ENDPOINTS.GET_TX + txId, null, callback);
	}

	getSolAccountStakeRewards(account, callback) {
		this.get(
			this.SOL_ENDPOINTS.GET_ACCOUNT_STAKE_REWARDS + account,
			null,
			callback
		);
	}
	getSolAccountStakeAccounts(account, callback) {
		this.get(
			this.SOL_ENDPOINTS.GET_ACCOUNT_STAKE_ACCOUNTS + account,
			null,
			callback
		);
	}
	getSolAccountTxs(account, callback) {
		this.get(this.SOL_ENDPOINTS.GET_ACCOUNT_TXS + account, null, callback);
	}
	getSolAccountTxsPaged(account, offset, callback) {
		this.get(this.SOL_ENDPOINTS.GET_ACCOUNT_TXS + account, {offset: offset}, callback);
	}
	getSolAccount(account, callback) {
		this.get(this.SOL_ENDPOINTS.GET_ACCOUNT + account, null, callback);
	}

	getEurPrice(crypto, callback) {
		this.get(this.LAMBDA_ENDPOINT + "getEurPrice/" + crypto, null, callback);
	}
	getUsdPrice(crypto, callback) {
		this.get(this.LAMBDA_ENDPOINT + "getUsdPrice/" + crypto, null, callback);
	}
}

export default API;
