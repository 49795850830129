import {initializeApp} from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {getFirestore, doc, query, orderBy, limit, startAfter, where, getDoc, getDocs, setDoc, addDoc, updateDoc, deleteField, arrayUnion, arrayRemove, collection, Timestamp} from 'firebase/firestore/lite';
import {getAuth, signOut, signInWithPopup, signInWithRedirect, getRedirectResult, GoogleAuthProvider} from "firebase/auth";
import Gtag from "@/assets/js/Gtag";
import Utils from "@/assets/js/Utils";

class Firebase {
	constructor() {
		this.RECAPTCHA_PUBLIC = window?.processDebugToken ? window.processDebugToken : '6Lc1RTEeAAAAAEifyyrjkoLPBThbjb0KRnJ1LxK_';
		this.CONFIG = {
			apiKey: "AIzaSyBBjvl4F0_FS2TuikAl80-s9pP3PXp-Z3M",
			authDomain: "auth.mezcla.app",
			//authDomain: "g--explorer.firebaseapp.com",
			projectId: "g--explorer",
			storageBucket: "g--explorer.appspot.com",
			messagingSenderId: "883081407056",
			appId: "1:883081407056:web:d51e752e8d2e4cf1163f55"
		}
		this.PATH_USERS = 'users';
		this.PATH_ADDRESSES = 'vanity_addresses';
		this.PATH_CHARGES = 'charges';
		this.PATH_PRIVATE_KEYS = 'private_keys';
		this.app = initializeApp(this.CONFIG);
		this.initCaptcha();
		this.googleAuthProvider = new GoogleAuthProvider();
		this.auth = getAuth();
		this.db = getFirestore(this.app);
		this.uid = null;
		this.userImage = null;
		this.userEmail = null;
		this.userDoc = null;
		this.chargesDoc = null;
		this.userData = {
			isUsd: true,
			isConventional: true,
			mainFiat: true,
			darkMode: true,
			addressesHistory: [],
			favourites: {},
		}
		this.vanityAddressesArray = null;
		this.lastAddressSnapshotFound = null;
		this.lastAddressSearchQuery = null;
	}
	static init() {
		if (Firebase.instance) {
			return Firebase.instance;
		}
		Firebase.instance = new Firebase();
		return Firebase.instance;
	}
	initCaptcha() {
		if(window?.processDebugToken) { self.FIREBASE_APPCHECK_DEBUG_TOKEN = this.RECAPTCHA_PUBLIC; }
		initializeAppCheck(this.app, {
			provider: new ReCaptchaV3Provider(this.RECAPTCHA_PUBLIC),
			isTokenAutoRefreshEnabled: true
		});
	}

	getUserDoc() {
		if(this.userDoc) {
			return this.userDoc;
		} else {
			this.userDoc = doc(this.db, this.PATH_USERS, this.uid);
			return this.userDoc;
		}
	}
	getChargesDoc() {
		if(this.chargesDoc) {
			return this.chargesDoc;
		} else {
			this.chargesDoc = doc(this.db, this.PATH_CHARGES, this.uid);
			return this.chargesDoc;
		}
	}
	getVanityAddresses(callback) {
		if(this.vanityAddressesArray) {
			return this.vanityAddressesArray;
		} else {
			getDocs(query(collection(this.db, this.PATH_ADDRESSES)))
				.then(snap=>{
					if(snap){
						this.vanityAddressesArray = [];
						snap.forEach((doc) => {
							let pushingObj = Utils.deepObjCopy(doc.data());
							pushingObj.address = doc.id;
							this.vanityAddressesArray.push(pushingObj);
						});
					} else {
						this.vanityAddressesArray = null;
					}
					callback(this.vanityAddressesArray);
				}).catch(err=>{
					console.log('Error fetching vanity addresses! ', err);
					this.vanityAddressesArray = null;
					callback(this.vanityAddressesArray);
				});
		}
	}
	getUserCharges(callback) {
		if(this.uid) {
			getDoc(this.getChargesDoc())
				.then(chargesSnap => {
					if(chargesSnap.exists()) {
						callback(chargesSnap.data());
					} else {
						callback(false);
					}
				})
				.catch(err=>{
					console.log('Get Charges Firebase error! ', err)
					callback(false);
				});
		} else {
			callback(false);
		}
	}
	getTimestamp = (date = null)=> { return Timestamp.fromDate(date ? date : new Date()); }

	logout() {
		let self = this;
		signOut(this.auth)
			.then(()=>{
				self.uid = null;
				window.location.reload();
			})
			.catch(error=>{
				console.warn('logout error: ', error);
				self.uid = null;
				window.location.reload();
			});

		Gtag.sendAnalytic( "Logout", 'Auth', 'Logout from Google account' );
	}
	loginRedirect(callback, callbackError) {
		signInWithRedirect(this.auth, this.googleAuthProvider)
			.then((result) => {
				this.handleLoginResponse(result.user, callback);
			}).catch((error) => {
				this.handleLoginErrorResponse(error, callbackError);
			});
	}
	loginPopup(callback, callbackError = null) {
		signInWithPopup(this.auth, this.googleAuthProvider)
			.then((result) => {
				this.handleLoginResponse(result.user, callback);
			}).catch((error) => {
				this.handleLoginErrorResponse(error, callbackError);
			});
	}
	checkLogin(callback, callbackError = null) {
		getRedirectResult(this.auth)
			.then(result => {
				if(result) {
					//const credential = GoogleAuthProvider.credentialFromResult(result);
					this.handleLoginResponse(result.user, callback);
				} else if(this.auth.currentUser) {
					this.handleLoginResponse(this.auth.currentUser, callback);
				} else {
					callback(false);
				}
			}).catch(error => {
				this.handleLoginErrorResponse(error, callbackError);
			});
	}
	handleLoginResponse(credential, callback) {
		if(credential) {
			this.uid = credential.uid;
			this.userImage = credential.photoURL;
			this.userEmail = credential.email;
			Gtag.sendAnalytic( "Login", 'Auth', 'Login with Google account' );
		}
		callback(!!credential);
	}
	handleLoginErrorResponse(error, callback = null) {
		console.log('firebase google check login redirect error: ', error);
		if(callback) { callback(false) }
	}

	getUserData(callback, callbackError = null) {
		if(this.uid) {
			getDoc(this.getUserDoc())
				.then(userSnap => {
					if(userSnap.exists()) {
						this.userData = userSnap.data();
						callback(this.userData);
					} else {
						this.createUser(callback, callbackError)
					}
				})
				.catch(e=>{ if(callbackError) { callbackError(e) }
				});
		} else {
			callback(null);
		}
	}
	createUser(callback, callbackError = null) {
		this.updateMultipleFields(this.userData, callback, callbackError);
		Gtag.sendAnalytic( "Signup", 'Auth', 'Signup with Google account' );
	}

	delayedCall(fnName, params) {
		if(this[fnName+'Timeout']) {
			clearTimeout(this[fnName+'Timeout']);
		}
		this[fnName+'Timeout'] = setTimeout(()=>{
			this[fnName](...params);
		}, 2500);
	}
	updateMultipleFields(updateObject, callback = null, callbackError = null) {
		if(this.uid) {
			setDoc(this.getUserDoc(), updateObject, {merge: true})
				.then(() => { callback(this.userData) })
				.catch(e=>{ if(callbackError) { callbackError(e) }
				});
		}
	}
	updateField(field, value, callback = null, callbackError = false) {
		if(this.uid) {
			let data = {};
			data[field] = value;
			updateDoc(this.getUserDoc(), data)
				.then(() => { if(callback) { callback() } })
				.catch(e=>{ if(callbackError) { callbackError(e) }
				});
		}
	}
	updateArrayField(field, value, callback = null, callbackError = false) {
		if(this.uid) {
			this.updateField(field, arrayUnion(value), callback, callbackError);
		}
	}
	removeFromArrayField(field, value, callback = null, callbackError = false) {
		if(this.uid) {
			this.updateField(field, arrayRemove(value), callback, callbackError);
		}
	}

	updateAddressesHistory(newAddress, add = true) {
		if(!this.userData || !this.uid) { return }
		if(!this.userData.addressesHistory || !Array.isArray(this.userData.addressesHistory)) { this.userData.addressesHistory = []; }
		let foundAddress = this.userData.addressesHistory.find(addr=>{ return addr.address === newAddress.address });
		if(add) {
			if(!foundAddress) { this.userData.addressesHistory.push(newAddress); }
			this.updateArrayField('addressesHistory', newAddress);
		} else {
			if(foundAddress) {
				let index = this.userData.addressesHistory.indexOf(foundAddress);
				this.userData.addressesHistory.splice(index, 1);
			}
			this.removeFromArrayField('addressesHistory', newAddress);
		}
	}
	updateFavourites(newFavourites, callback, callbackError) {
		if(this.userData.favourites !== newFavourites) {
			this.userData.favourites = newFavourites;
			this.updateField('favourites', newFavourites, callback, callbackError);
		}
	}
	updateSingleFavourite(address, body, callback = null, callbackError = null) {
		this.userData.favourites[address] = body;
		this.updateField('favourites.'+address, body, callback, callbackError);
	}
	deleteFavourite(address, callback, callbackError = null) {
		delete this.userData.favourites[address];
		this.updateField('favourites.'+address, deleteField(), callback, callbackError);
	}
	updateUsd(value, delay = true) {
		if(delay) {
			this.delayedCall('updateUsd', [value, false]);
		} else {
			this.updateField('isUsd', value);
		}
	}
	updateConventional(value, delay = true) {
		if(delay) {
			this.delayedCall('updateConventional', [value, false]);
		} else {
			this.updateField('isConventional', value);
		}
	}
	updateMainFiat(value, delay = true) {
		if(delay) {
			this.delayedCall('updateMainFiat', [value, false]);
		} else {
			this.updateField('mainFiat', value);
		}
	}
	updateDarkMode(value, delay = true) {
		if(delay) {
			this.delayedCall('updateDarkMode', [value, false]);
		} else {
			this.updateField('darkMode', value);
		}
	}
	searchAddresses(strSearch, callback) {
		if(!!this.lastAddressSearchQuery && this.lastAddressSearchQuery !== strSearch) {
			this.lastAddressSearchQuery = null;
			this.lastAddressSnapshotFound = null;
		}
		const end = strSearch.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
		getDocs(
			query(collection(this.db, this.PATH_ADDRESSES),
				where('pattern', '>=', strSearch),
				where('pattern', '<', end),
				orderBy("pattern"),
				startAfter(this.lastAddressSnapshotFound),
				limit(50)
			))
			.then(snapshots => {
				if(snapshots && typeof snapshots?.docs === 'object') {
					let finalObj = {};
					snapshots.docs.forEach(doc => {
						finalObj[doc.id] = doc.data();
					});
					this.lastAddressSearchQuery = strSearch;
					this.lastAddressSnapshotFound = snapshots.docs.length === 50 ? snapshots.docs[snapshots.docs.length - 1] : null;
					callback(finalObj);
				} else {
					callback(null);
				}
			});

	}
	getAddress(address, callback) {
		getDoc(doc(this.db, this.PATH_ADDRESSES, address))
			.then(addressSnap => {
				if(addressSnap.exists()) {
					callback(addressSnap.data());
				} else {
					callback(null);
				}
			})
			.catch(e=>{
				console.log('error retrieving address: ', e);
				callback(false);
			});
	}
	getPrivKey(address, callback) {
		getDoc(doc(this.db, this.PATH_PRIVATE_KEYS, address))
			.then(keySnap => {
				if(keySnap.exists()) {
					callback(keySnap.data());
				} else {
					callback(null);
				}
			})
			.catch(e=>{
				console.log('error retrieving private key: ', e);
				callback(false);
			});
	}

	pastTimestamp(timestamp) {
		return timestamp < Firebase.init().getTimestamp();
	}
	pastTimestampMoreThan5MinsAgo(timestamp) {
		let newDate = new Date();
		newDate.setMinutes(new Date().getMinutes() - 5);
		return timestamp < Firebase.init().getTimestamp(newDate);
	}

}

export default Firebase;
