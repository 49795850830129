// Styles
//import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import colors from 'vuetify/lib/util/colors'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi-svg'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
	theme: {
		themes: {
			dark: {
				colors: {
					primary: colors.orange.base
				}
			},
			light: {
				colors: {
					primary: colors.orange.base
				}
			}
		}
	},
})
