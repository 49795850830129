<template>
  <div class="row">
    <div class="col-12 centered pb-20">
      <p class="text-h3 text-align-center">Introduction to Bitcoin</p>
      <p class="text-subtitle-1">An updated list of resources to better understand the digital money revolution</p>
    </div>
    <div class="col-12 centered">
      <v-icon class="mt-5" size="x-large">{{ icons.work }}</v-icon>
      <p class="text-body-1" style="max-width: 400px">Under construction</p>
    </div>
  </div>
</template>

<script>

import { mdiAccountHardHat, } from "@mdi/js";

export default {
  name: "Intro",
  props: {

  },
  data() {
    return {
      icons: {
        work: mdiAccountHardHat,

      },
      metadata: {
        title: "Introduction to Bitcoin",
        description: "Resources on Bitcoin the protocol and bitcoin the currency.",
      },
    }
  },
  computed: {

  },
  methods: {

  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth", });

  },
  created() {
    document.title = this.metadata.title;
    document.querySelector('meta[name="description"]').setAttribute("content", this.metadata.description);
  },
}
</script>

<style lang="scss" scoped>

</style>