<template>
  <div>
    <v-card v-ripple="!isActiveForm" ref="address-card" class="mx-auto glassed" :class="isActiveForm?'':'pointer'" max-width="640">
      <v-container>
        <v-row>
          <v-col v-show="!isActiveForm" cols="12" md="12">
            <v-card-title>{{ formValue }}</v-card-title>
            <v-card-subtitle>Searched address</v-card-subtitle>
          </v-col>
          <v-col v-show="isActiveForm" cols="12" md="12">
            <v-form>
              <p class="mb-2">Search {{ $route.name.split(' ')[0] }} address</p>
              <v-text-field
                  :error="errorForm && !isLoading"
                  :error-messages="inputErrorMsg"
                  @keydown.enter.prevent="submitForm"
                  @focus="showAutocomplete = true"
                  @blur="showAutocomplete = false"
                  color="orange"
                  label="Insert address"
                  :loading="isLoading"
                  :disabled="isLoading"
                  v-model="formValue"
              >
                <template v-slot:append>
                  <transition :name="!!formValue ? 'slide-left' : 'slide-right'">
                    <v-btn v-show="!!formValue" @click="eraseForm" :icon="icons.close" flat></v-btn>
                  </transition>
                </template>
              </v-text-field>
              <p v-show="errorForm && !isLoading" class="text-caption text-red-accent-1">{{ inputErrorMsg }}</p>
              <AutocompleteDropdown
                  ref="autocomplete"
                  :is-shown="showAutocomplete"
                  :input-value="formValue"
                  :is-loading="isLoading"
                  @clicked-address="handleHistoryClick"
                  @clicked-delete-address="clickedDeleteAddress"
              >
              </AutocompleteDropdown>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-show="isActiveForm" @click.stop="submitForm" :class="isLoading?'disabled':''" ripple color="transparent" text-color="orange" text>Search</v-btn>
        <v-icon v-show="!isActiveForm" color="orange" size="small">{{ icons.pencil }}</v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import AutocompleteDropdown from "@/components/AutocompleteDropdown";
import {mdiPencil, mdiClose} from "@mdi/js";

export default {
  name: 'Form',
  components: { AutocompleteDropdown },
  props: {
    prefilledAddress: {
      type: String,
      default: ''
    },
    activeForm: {
      type: Boolean,
      default: true
    },
    shouldLoad: {
      type: Boolean,
      default: false
    },
    errorForm: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      icons: {
        pencil: mdiPencil,
        close: mdiClose,
      },
      formValue: '',
      searchedValue: '',
      isConventional: true,
      idUsd: true,
      showAutocomplete: false,
    }
  },
  methods: {
    submitForm() {
      if(!this.isLoading && this.input && this.formValue) {
        let parsedValue = this.formValue.replace(/\s+/g, '');
        if(parsedValue) {
          this.formValue = parsedValue;
          this.searchedValue = this.formValue;
          this.$emit('submit', this.formValue);
        }
      }
    },
    openAddressContainer() {
      if (!this.activeForm) {
        this.$emit('openForm', true);
        setTimeout(() => {
          if (this.input) {
            this.input.focus();
          }
        }, 50);
      }
    },
    handleHistoryClick(address) {
      if(!this.isLoading && this.input) {
        this.formValue = address;
        this.submitForm();
      }
    },
    clickedDeleteAddress(address) {
      if(!this.isLoading && this.input) {
        if(this.input?.focus !== undefined) {this.input.focus()}
        if(this.formValue === address) { this.formValue = ''; }
        this.$emit('deleteAddressFromHistory', address);
      }
    },
    clearError() { if(this.errorForm) {this.$emit('clearError', true)}; },
    eraseForm() {
      this.formValue = '';
      if (this.input) {
        this.input.focus();
      }
    },
  },
  computed: {
    input() {
      return this.$refs["address-card"]?.$el?.querySelector('input') ? this.$refs["address-card"]?.$el?.querySelector('input') : null;
    },
    inputErrorMsg() { return 'Address '+this.searchedValue+' not found'; },
    isLoading() { return this.$props.shouldLoad; },
    isActiveForm() {
      return this.activeForm || this.errorForm;
    },
  },
  watch: {
    prefilledAddress: function(newVal, oldVal) {
      if(!!oldVal && !!newVal) {
        this.searchedValue = this.$props.prefilledAddress;
        this.formValue = this.$props.prefilledAddress;
      }
    },
  },
  mounted() {
    this.$refs["address-card"].$el.addEventListener('click', this.openAddressContainer);
    this.input.addEventListener('input', this.clearError);
    if(this.$props.prefilledAddress) {
      this.searchedValue = this.$props.prefilledAddress;
      this.formValue = this.$props.prefilledAddress;
    }
  }
}
</script>

<style lang="scss">
.v-input__details {
  margin-bottom: 0;
}
</style>