<template>
  <div ref="background" class="background-balls">

  </div>
</template>

<script>
import Utils from "@/assets/js/Utils";

export default {
  name: "BackgroundBalls",
  props: {isDark: Boolean},
  data() {
    return {
      isResizing: false,
    }
  },
  computed: {
    colors() {
      //return ['red', 'pink', 'purple', 'indigo', 'blue', 'cyan', 'teal', 'green', 'lime', 'amber', 'brown', 'blue-grey'];
      return this.$props.isDark ? ['grey-darken-1', 'grey-darken-2', 'grey-darken-3'] : ['grey-lighten-1', 'grey-lighten-2', 'grey-lighten-3'];
    }
  },
  methods: {
    spawnBalls() {
      let numBalls = Utils.getRndInteger(1, 4);
      for (let i = 0; i < numBalls; i++) {
        this.spawnBall();
      }
    },
    spawnBall() {
      let selectedColor = this.colors[Utils.getRndInteger(0, this.colors.length - 1)]
      let minorDim = this.$refs.background.clientHeight < this.$refs.background.clientWidth ? this.$refs.background.clientWidth : this.$refs.background.clientHeight;
      let dim = Utils.getRndInteger(550, minorDim / 2);
      let newBall = document.createElement('div');
      newBall.classList.add('ball');
      newBall.classList.add('bg-' + selectedColor);
      newBall.style.height = dim + 'px';
      newBall.style.width = dim + 'px';
      let left = this.$refs.background.clientWidth / 2 - dim / 2;
      let top = this.$refs.background.clientHeight / 2 - dim / 2;
      newBall.style.transform = 'translate(' + left + 'px, ' + top + 'px)';
      this.$refs.background.appendChild(newBall);
      this.moveBall(newBall);
    },
    moveBall(ball) {
      let minTop = 0;
      let minLeft = 0;
      let maxTop = ball.parentElement.clientHeight - ball.clientHeight;
      let maxLeft = ball.parentElement.clientWidth - ball.clientWidth;

      let left = Utils.getRndInteger(minLeft, maxLeft);
      let top = Utils.getRndInteger(minTop, maxTop);
      let borderPosition = Utils.getRndInteger(1, 4);
      if (borderPosition === 1) {
        left = minLeft;
      } else if (borderPosition === 2) {
        left = maxLeft;
      } else if (borderPosition === 3) {
        top = minTop;
      } else {
        top = maxTop;
      }

      ball.style.transform = 'translate(' + left + 'px, ' + top + 'px)';
      setTimeout(() => {
        if(ball && ball.parentElement) {
          this.moveBall(ball);
        }
      }, 10000);
    },
    switchColors(ball, isDark) {
      if (isDark === true) {
        if (ball.classList.contains('bg-grey-lighten-1')) {
          ball.setAttribute('class', 'ball bg-grey-darken-1')
        } else if (ball.classList.contains('bg-grey-lighten-2')) {
          ball.setAttribute('class', 'ball bg-grey-darken-2')
        } else if (ball.classList.contains('bg-grey-lighten-3')) {
          ball.setAttribute('class', 'ball bg-grey-darken-3')
        }
      } else {
        if (ball.classList.contains('bg-grey-darken-1')) {
          ball.setAttribute('class', 'ball bg-grey-lighten-1')
        } else if (ball.classList.contains('bg-grey-darken-2')) {
          ball.setAttribute('class', 'ball bg-grey-lighten-2')
        } else if (ball.classList.contains('bg-grey-darken-3')) {
          ball.setAttribute('class', 'ball bg-grey-lighten-3')
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.spawnBalls();
    }, 100);
  },
  watch: {
    isDark: function (newVal, oldVal) {
      document.querySelectorAll('.ball').forEach(ball => {
        this.switchColors(ball, newVal);
      })
    }
  }
}
</script>

<style lang="scss">
.background-balls {
  position: fixed;
  height: 100vh;
  width: 100%;
}

.ball {
  opacity: 20%;
  position: absolute;
  border-radius: 50%;
  transition: transform 12s linear;
}
</style>