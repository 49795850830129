<template>
  <div class="row">
    <div class="col-12 centered pb-20">
      <p class="text-h4 text-align-center">Bitcoin signature verifier</p>
      <p class="text-subtitle-1">Easily check for the authenticity of a signature</p>
      <p class="text-subtitle-2 opacity-60">Currently only <i>Legacy addresses</i> are supported (starting with 1)</p>
    </div>
    <div class="col-12 centered">
      <v-card
          style="width: 350px"
          rounded
          color="transparent"
          flat
      >
        <div class="col-12 glassed" style="overflow: hidden">
          <v-form
              style="overflow: hidden"
              @input="updatedForm"
              @submit="submit"
          >
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      @keydown.enter.prevent="submit"
                      :color="correctForm ? 'green-accent-2' : ''"
                      :error="errorForm || errorAddress"
                      v-model="address"
                      label="Address"
                      placeholder="1BitJwhtg8fQdX...53GrSNRwQ"
                      ref="address-field"
                      :messages="addressErrorMsg"
                      required
                  >
                    <template v-if="correctForm || errorForm" v-slot:append>
                      <v-icon :color="correctForm ? 'green-accent-3' : 'red'"> {{icons[correctForm ? 'checkbox' : 'alert']}} </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      auto-grow
                      placeholder="Message goes here"
                      @keydown.enter.prevent="submit"
                      :color="correctForm ? 'green-accent-2' : ''"
                      :error="errorForm"
                      v-model="message"
                      ref="message-field"
                      required
                  >
                    <template v-slot:label>
                      <div> Message </div>
                    </template>
                    <template v-if="correctForm || errorForm" v-slot:append>
                      <v-icon :color="correctForm ? 'green-accent-3' : 'red'"> {{icons[correctForm ? 'checkbox' : 'alert']}} </v-icon>
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      @keydown.enter.prevent="submit"
                      :color="correctForm ? 'green-accent-2' : ''"
                      :error="errorForm"
                      v-model="signature"
                      label="Signature"
                      :messages="errorForm ? signatureErrorMsg : ''"
                      placeholder="Gxhr7aVemDc4YtU...i7pxcXkcocDU="
                      required
                  >
                    <template v-if="correctForm || errorForm" v-slot:append>
                      <v-icon :color="correctForm ? 'green-accent-3' : 'red'"> {{icons[correctForm ? 'checkbox' : 'alert']}} </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <transition name="gain-height">
                <v-row v-if="!!errorForm || !!correctForm">
                  <v-col cols="12" class="mb-4 text-center">
                    <p class="text-h5">Result</p>
                    <p :class="!!correctForm ? 'text-green-accent-3' : 'text-red-accent-1'">{{ !!correctForm ? "Signature is VALID" : "Signature is INVALID" }}</p>
                    <v-icon class="my-5">{{!!correctForm ? icons.checkbox : icons.alert}}</v-icon>
                  </v-col>
                </v-row>
              </transition>
            </v-container>
            <v-divider :class="errorForm ? 'bg-error' : correctForm ? 'bg-green-accent-3' : ''"></v-divider>
            <v-card-actions>
              <v-btn
                  text
                  tabindex="-1"
                  color="transparent"
                  @click="eraseForm"
                  :disabled="!this.address && !this.signature && !this.message"
              >
                Erase
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!formIsValid"
                  text
                  color="primary"
                  type="submit"
              >
                Verify
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </div>
    <div class="col-12 pt-100 align-end">
      <div>
        <div style="max-width: 344px; min-width: 250px;" class="glass purple tilt-card-verify no-select pointer mx-auto m-20" data-tilt>
          <div class="glass-content">
            <v-card
                @click="$router.push('/shop')"
                v-ripple
                color="transparent"
            >
              <v-card-actions>
                <v-btn color="transparent" text class="opacity-60 appear-up">Go to Shop</v-btn>
                <v-spacer></v-spacer>
                <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pt-40 align-end">
      <div>
        <div style="max-width: 344px; min-width: 250px;" class="glass orange tilt-card-verify no-select pointer mx-auto m-20" data-tilt>
          <div class="glass-content">
            <v-card
                @click="$router.push('/btc')"
                v-ripple
                color="transparent"
            >
              <v-card-actions>
                <v-btn color="transparent" text class="opacity-60 appear-up">Go to explorer</v-btn>
                <v-spacer></v-spacer>
                <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiCheckboxMarkedCircle, mdiAlertCircle, mdiArrowRight } from "@mdi/js";

/*
* In package.json > dependencies:
*
*  "bip32": "^3.0.1",
   "bitcoinjs-lib": "^4.0.5",
   "bitcoinjs-message": "^2.2.0",
   "ecpair": "^2.0.1",
*
* */
//import { ECPair } from 'bitcoinjs-lib';
import { verify, /*sign*/ } from 'bitcoinjs-message';
//import { randomBytes } from 'crypto';

export default {
  name: "Verifier",
  props: {

  },
  data() {
    return {
      icons: {
        checkbox: mdiCheckboxMarkedCircle,
        alert: mdiAlertCircle,
        arrowRight: mdiArrowRight,
      },
      metadata: {
        title: "Bitcoin message verifier",
        description: "Easily verify the authenticity of a signature.",
      },
      bitcoin: null,
      address: null,
      message: null,
      signature: null,
      errorForm: false,
      correctForm: false,
      updated: false,
      illegalAddressChars: ["l", "I", "O", "0", " ", "'", ".", ","],
      validSignatureErrors: ['Invalid signature length', 'Invalid signature parameter'],
      signatureErrorMsg: null,
    }
  },
  computed: {
    formIsValid () {
      return (
          this.address &&
          this.signature &&
          this.message &&
          this.updated
      )
    },
    addressErrorMsg() {
      let formatError = !!this.address && this.address.startsWith('1') ? "Must be alphanumeric and cannot contain l, I, O, 0" : 'Address should start with "1"';
      return this.errorAddress ? formatError : '';
    },
    errorAddress() {
      let correctAddress = this.correctRegex();
      return !!this.address && !correctAddress;
    },
  },
  methods: {
    correctRegex() {
      if(!!this.address) {
        if(!this.address.startsWith('1')) { return false; }
        for(let i in this.illegalAddressChars) {
          let illegalChar = this.illegalAddressChars[i];
          if(this.address.includes(illegalChar)) { return false; }
        }
      }
      return true;
    },
    eraseForm() {
      this.address = null;
      this.message = null;
      this.signature = null;
      this.updated = false;
      this.errorForm = false;
      this.correctForm = false;
      this.$refs['address-field'].focus();
    },
    updatedForm(){
      this.updated = true;
      this.errorForm = false;
      this.correctForm = this.verifyMessage(this.address , this.signature, this.message);
      //console.log('updated form values: ',this.address , this.signature, this.message)
      if(this.correctForm) {
        this.updated = false;
        window?.document?.activeElement?.blur();
      }
    },
    submit(){
      //console.log('values: ',this.address , this.signature, this.message)
      this.updated = false;
      this.errorForm = false;
      this.correctForm = false;

      if(this.verifyMessage(this.address , this.signature, this.message)) {
        this.correctForm = true;
        window?.document?.activeElement?.blur();
      } else {
        this.errorForm = true;
        this.$refs['message-field'].focus();
      }

    },
    verifyMessage(address, signature, message){
      if(!address || !signature || !message) { return false; }
      try {
        //Example address from bitcoinjs-lib
        //let keyPair = ECPair.fromWIF('L4rK1yDtCWekvXuE6oXD9jCYfFNV2cWRpVuPLBcCU2z8TrisoyY1')
        //let privateKey = keyPair.privateKey
        //let message = 'This is an example of a signed message.'
        //let signature = sign(message, privateKey, keyPair.compressed, { extraEntropy: randomBytes(32) })
        //let address = '1F3sAm6ZtwLAUnj7d38pGFxtP3RVEvtsbV';
        //console.log(signature.toString('base64'))
        //console.log(verify(message, address, signature.toString('base64')));

        return verify(message, address, signature);
      } catch (error) {
        console.warn('Message verifier error: ', !!error?.message ? error.message : error);
        this.signatureErrorMsg = !!error?.message && this.validSignatureErrors.includes(error.message) ? error.message : null;
        return false;
      }
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth", });
    if(!!window.VanillaTilt) {
      window.VanillaTilt.init(document.querySelectorAll('.tilt-card-verify'));
    }
  },
  created() {
    document.title = this.metadata.title;
    document.querySelector('meta[name="description"]')?.setAttribute("content", this.metadata.description);
  },
}
</script>

<style lang="scss">
  textarea, textarea * {
    color: unset;
    color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
  }
</style>