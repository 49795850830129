<template>
  <div>
    <div class="col-12 centered">
      <v-switch
          v-model="timelineView"
          v-if="!showTxBreakdown"
          label="Timeline view"
          hide-details
          color="primary"
      ></v-switch>
    </div>
    <transition name="slide-left" mode="out-in">
      <div class="col-12" v-if="!!timelineView && !showTxBreakdown">
        <p class="text-align-center text-overline">{{ totalTxs }} transactions</p>
        <v-divider></v-divider>
        <div class="mt-5"></div>
        <TxsTimeline :class="isPaginationLoading ? 'no-pointer-events' : ''" @fetch-paged-txs="fetchPagedTxs(null, true)" @fetch-detail="viewTransaction" :loading="isPaginationLoading" :days-parsed-txs="daysParsedTxs" :has-non-loaded="hasNonLoadedTxs"></TxsTimeline>
        <div v-show="isPaginationLoading" class="row pt-5 px-5">
          <v-progress-linear
              class="opacity-60"
              height="2"
              indeterminate
          ></v-progress-linear>
        </div>
      </div>
      <div class="col-12" v-else-if="!showTxBreakdown">
        <p class="text-align-center text-overline">{{ currentTxOffset + 1 }} - {{ currentTxOffset + currentPagedTxs.length }} of
          {{ totalTxs }} transactions</p>
        <v-divider></v-divider>
        <v-progress-linear
            v-show="isPaginationLoading"
            class="opacity-60"
            height="2"
            indeterminate
        ></v-progress-linear>
        <v-table class="bg-transparent" fixed-header v-if="parsedTxs.length > 0">
          <thead>
          <tr>
            <th class="bg-transparent text-button font-weight-bold opacity-60">Value</th>
            <th class="bg-transparent text-button font-weight-bold opacity-60 pa-0">Date</th>
            <th class="bg-transparent text-button font-weight-bold opacity-60 text-align-center hide-mobile pa-0">ID</th>
            <th class="bg-transparent text-button font-weight-bold opacity-60 text-align-center pa-0"><p class="hidden-xs">Status</p></th>
            <th v-if="$route.path.startsWith('/btc')" class="bg-transparent pa-0 opacity-60"></th>
          </tr>
          </thead>
          <tbody>
          <tr class="no-select word-wrap" v-for="(tx, index) in currentPagedTxs" :key="index">
            <td class="pr-0 pl-2">
              <p v-if="tx.value !== 0 || !tx.details"
                  class="text-md-h6 text-sm-body-1 text-xs-caption text-body-2 opacity-80"
                  style="min-width: 80px">
                <span :class="tx.value >= 0 ? 'outputs-amount' : 'inputs-amount'">
                {{ mainFiat ? tx.fiatVal : tx.cryptoVal }}
                </span>
              </p>
              <p v-if="tx.value !== 0 || !tx.details" class="text-caption opacity-60">
                {{ mainFiat ? tx.cryptoVal : tx.fiatVal }}</p>

              <p v-if="tx.value === 0 && !!tx.details"
                  class="text-sm-body-1 text-xs-caption text-body-2 opacity-80"
                  style="min-width: 80px">
                {{ mainFiat ? tx.fiatVal : tx.cryptoVal }}
              </p>
              <p v-if="tx.value === 0 && !!tx.details"
                  class="text-caption opacity-60">{{ tx.details }}</p>
            </td>
            <td class="pa-0">
              <p v-if="!isNaN(tx.date.getTime())" class="text-caption opacity-60">{{ tx.date.toLocaleDateString() }}</p>
              <p v-if="!isNaN(tx.date.getTime())" class="text-caption opacity-60">{{ tx.date.toLocaleTimeString() }}</p>
              <p v-else class="text-caption opacity-60">Just now - pending</p>
            </td>
            <td class="hide-mobile pa-0" style="max-width: 100px">
              <Tooltip text="Click to copy">
                <p @click="clickToCopy" class="tx-id-text truncate monospace opacity-40">{{ tx.txId }}</p>
              </Tooltip>
            </td>
            <td v-if="tx.confirmed" class="text-align-center opacity-80 pa-0">
              <Tooltip text="Confirmed">
                <v-icon color="green-accent-3">{{ icons.check }}</v-icon>
              </Tooltip>
            </td>
            <td v-if="!tx.confirmed" class="text-align-center opacity-80 pa-0">
              <Tooltip :text="tx.failed ? 'Failed' : 'Pending'">
                <v-icon v-if="!tx.failed" color="amber-darken-2">{{ icons.timerSand }}</v-icon>
                <v-icon v-if="tx.failed" color="red-darken-2">{{ icons.close }}</v-icon>
              </Tooltip>
            </td>
            <td v-if="$route.path.startsWith('/btc')" class="pa-0 text-align-center">
              <v-btn @click="viewTransaction(tx)" icon flat color="transparent" text-color="orange">
                <v-icon>{{ icons.arrowRight }}</v-icon>
              </v-btn>
            </td>

          </tr>
          </tbody>
        </v-table>
        <v-divider></v-divider>
        <v-pagination
            v-if="parsedTxs.length > 0"
            class="mt-3 text-orange truncate"
            size="small"
            theme="default"
            v-model="currentTxPage"
            :length="numberOfTxPages"
            :total-visible="1"
            :disabled="isPaginationLoading"
            @update:modelValue="fetchPagedTxs"
        ></v-pagination>
      </div>
      <div class="col-12" v-else-if="showTxBreakdown">
        <TransactionDetail
            ref="detail"
            @back-txs="showTxBreakdown = false"
          :address="address"
          :curr-crypto="currCrypto"
          :is-usd="isUsd"
          :is-conventional="isConventional"
          :main-fiat="mainFiat"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip";
import TxsTimeline from "@/components/TxsTimeline";
import TransactionDetail from "@/components/TransactionDetail";
import CryptoUtils from "@/assets/js/CryptoUtils";
import { mdiStar, mdiArrowRight, mdiCheck, mdiClose, mdiTimerSandEmpty } from "@mdi/js";

export default {
  name: "TransactionsTable",
  components: {Tooltip, TransactionDetail, TxsTimeline},
  props: {
    address: {type: String},
    currCrypto: {type: Object},
    isUsd: {type: Boolean},
    isConventional: {type: Boolean},
    mainFiat: {type: Boolean},
    totalTxs: {type: Number},
  },
  data() {
    return {
      icons: {
        star: mdiStar,
        arrowRight: mdiArrowRight,
        close: mdiClose,
        check: mdiCheck,
        timerSand: mdiTimerSandEmpty,

      },
      txs: [],
      currentTxPage: 1,
      itemsPerTxPage: 10,
      isPaginationLoading: false,
      showTxBreakdown: false,
      timelineView: false,
    }
  },
  methods: {
    clickToCopy(e, customText = null) {
      navigator.clipboard.writeText(!!customText ? customText : e.currentTarget.innerText);
      let tooltip = e.currentTarget.parentElement.querySelector('.tooltiptext');
      if (tooltip) {
        let target = e.currentTarget;
        target.classList.add('text-orange', 'no-pointer-events');
        let oldText = tooltip.innerText;
        tooltip.innerText = 'Copied!';
        setTimeout(() => {
          target.classList.remove('text-orange');
        }, 50);
        setTimeout(() => {
          tooltip.innerText = oldText;
          target.classList.remove('no-pointer-events');
        }, 1000);
      }
    },
    fetchPagedTxs(page, forced = false) {
      if(this.hasNonLoadedTxs && (this.lastFullyLoadedPage === page || !!forced)) {
        this.$emit('fetchPagedTxs', true);
      }
    },
    viewTransaction(tx) {
      if(!this.isPaginationLoading) {
        this.$emit('fetchDetailTx', tx);
      }
    },
    resetTxs() {
      this.txs = [];
      this.currentTxPage = 1;
      this.showTxBreakdown = false;
    },
  },
  computed: {
    parsedTxs() {
      let cr = this.currCrypto;
      this.txs.forEach(tx => {
        tx.eurVal = CryptoUtils.numberToEur(tx.value, cr.eurPrice, cr.decimals);
        tx.usdVal = CryptoUtils.numberToUsd(tx.value, cr.usdPrice, cr.decimals);
        tx.unitVal = CryptoUtils.numberToUnit(tx.value, cr.unit);
        tx.conventionalVal = CryptoUtils.numberToConventional(tx.value, cr.symbol, cr.decimals);

        tx.fiatVal = this.isUsd ? tx.usdVal : tx.eurVal;
        tx.cryptoVal = this.isConventional ? tx.conventionalVal : tx.unitVal;
      });
      return this.txs;
    },
    daysParsedTxs() {
      let newArray = [];
      if(this.parsedTxs) {
        this.parsedTxs.forEach(tx => {
          let year = !isNaN(tx?.date?.getFullYear()) ? tx.date.getFullYear() : new Date().getFullYear();
          let month = !isNaN(tx?.date?.getMonth()) ? tx.date.getMonth() : new Date().getMonth();
          let day = !isNaN(tx?.date?.getDay()) ? tx.date.getDay() : new Date().getDay();
          let finalDay = new Date();
          finalDay.setFullYear(year);
          finalDay.setMonth(month);
          finalDay.setDate(day);
          let phrase = finalDay.toLocaleDateString(undefined, { year:"numeric", month:"short", day:"numeric"}) ;
          let foundPhrase = newArray.find(searchArray => { return searchArray.period === phrase });
          if(!foundPhrase) {
            newArray.push({period: phrase, txs: [tx]});
          } else {
            foundPhrase.txs.push(tx);
          }
        });
      }
      let returningArray = [];
      for(let periodIndex in newArray) {
        let period = newArray[periodIndex].period;
        newArray[periodIndex].txs.forEach(tx=>{
          returningArray.push({period: period, tx: tx});
          period = '';
        })
      }
      return returningArray;
    },
    hasNonLoadedTxs() { return this.parsedTxs.length < this.totalTxs },
    currentTxOffset() {
      return (this.currentTxPage - 1) * this.itemsPerTxPage;
    },
    currentPagedTxs() {
      return this.parsedTxs.slice(this.currentTxOffset, this.currentTxPage * this.itemsPerTxPage);
    },
    numberOfTxPages() {
      let wholePagesCount = Math.floor(this.totalTxs / this.itemsPerTxPage);
      wholePagesCount += this.totalTxs % this.itemsPerTxPage ? 1 : 0;
      return wholePagesCount;
    },
    lastFullyLoadedPage() {
      return this.hasNonLoadedTxs ? Math.floor(this.parsedTxs.length / this.itemsPerTxPage) : this.numberOfTxPages;
    },
  },
  mounted() {

  },
}
</script>

<style lang="scss">

p.tx-id-text {
  transition: color 1.5s ease;
  cursor: pointer;

  &.text-orange {
    opacity: .8;
    transition: none;
  }
}

.v-btn--variant-text {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.v-table__wrapper {
  overflow-x: unset;
  overflow-y: unset;
}
</style>