<template>
  <div class="text-center">
    <v-dialog
        v-model="isOpen"
        transition="slide-bottom"
        persistent
    >
      <v-card>
        <v-card-header><v-card-title>{{ title }}</v-card-title></v-card-header>
        <v-card-text v-html="message"></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="row">
            <v-spacer></v-spacer>
            <div>
              <v-btn text-color="orange" block @click="isOpen = false">I understand</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    message: String,
    title: String,
  },
  data () {
    return {
      isOpen: false,
    }
  },
}
</script>

<style lang="scss">
.v-overlay:not(.pulse .v-overlay) {
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}
.v-overlay__scrim:not(.pulse .v-overlay__scrim) {
  background-color: rgba(255,255,255,.2);
}
</style>