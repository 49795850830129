<template>
  <div class="row">
    <div class="col-12 pb-20">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6 flex-start medium-12 text-align-center order-medium-2">
          <p class="text-h4">{{$route.name}}</p>
          <p class="text-subtitle-2 mx-auto opacity-80">Review and edit favourite addresses</p>
        </div>
        <div class="col-3 medium-12 align-end order-medium-1 pt-20">
          <div>
            <v-menu
                transition="slide-y-transition"
                bottom
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    flat
                    color="orange"
                    v-bind="props"
                    variant="outlined"
                    class="glassed"
                    ref="cryptoSelectBtn"
                    @click="handleCryptoSelectClick(props)"
                >
                  <p>{{ currCrypto }}</p>
                  <v-icon>{{ icons[cryptoBtnArrow] }}</v-icon>
                </v-btn>
              </template>
              <v-list @click="$refs.cryptoSelectBtn.$el.click()" class="pa-0" color="#2d2d2d" style="color:white;" rounded>
                <v-list-item @click="clickCurrCrypto('btc')"><v-list-item-title class="text-button">BTC</v-list-item-title></v-list-item>
                <v-list-item @click="clickCurrCrypto('sol')"><v-list-item-title class="text-button">SOL</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pb-2">
      <p class="text-align-center text-overline">
        {{ currentPageOffset + 1 }} - {{ currentPageOffset + currentPagedFav.length }} of {{ favouritesArray.length }} favourites</p>
    </div>
    <div class="col-12 glassed" v-show="favouritesArray.length > 0">
      <v-progress-linear
          v-show="isLoading"
          class="opacity-60"
          height="2"
          indeterminate
      ></v-progress-linear>
      <v-table class="bg-transparent" :class="isLoading ? 'disabled' : ''" fixed-header>
        <thead>
        <tr>
          <th class="bg-transparent opacity-60"></th>
          <th @click="clickColumn('name')" class="bg-transparent pointer text-button font-weight-bold pa-0">

            <div class="row align-center flex-nowrap">
              <p class="mr-2" :class="sortField === 'name' ? 'text-orange' : 'opacity-60'">Name</p>
              <v-icon color="orange" v-show="sortField === 'name'" :class="sortAsc ? 'reversed-icon' : ''">{{ icons.sortVariant }}</v-icon>
            </div>
          </th>
          <th @click="clickColumn('address')" class="bg-transparent pointer text-button font-weight-bold pa-0">
            <div v-show="!editingFavAddress" class="row align-center flex-nowrap">
              <p class="mr-2" :class="sortField === 'address' ? 'text-orange' : 'opacity-60'">Address</p>
              <v-icon color="orange" v-show="sortField === 'address'" :class="sortAsc ? 'reversed-icon' : ''">{{ icons.sortVariant }}</v-icon>
            </div>
          </th>
          <th class="bg-transparent text-button font-weight-bold text-align-center pa-0"><p class="opacity-60 hidden-xs">Action</p></th>
        </tr>
        </thead>
        <tbody>
        <tr class="no-select word-wrap" :class="!!editingFavAddress && editingFavAddress !== fav.address ? 'opacity-20' : ''" v-for="fav in currentPagedFav" :key="fav.address">
          <td class="pr-0 pl-2">
            <v-badge v-show="editingFavAddress !== fav.address" :color="fav.color" inline></v-badge>

            <v-menu v-if="editingFavAddress === fav.address" :model-value="showColorMenu" offset-y>
              <template v-slot:activator="{ props }">
                <div class="row centered pr-2">
                  <v-btn
                      flat
                      size="compact"
                      :color="editingFavColor"
                      v-bind="props"
                      elevation="1"
                      @click.stop="handleColorClick"
                      :icon="icons[colorBtnArrow]"
                  >
                  </v-btn>
                </div>
              </template>
              <v-list color="#2d2d2d" elevation="2" rounded max-width="150" width="112">
                <v-badge @click="clickColorBadge(color)" class="pointer" v-for="(color, index) in availableColors" :key="index" inline :color="color"></v-badge>
              </v-list>
            </v-menu>
          </td>
          <td class="pa-0 address-name-text" :style="!!editingFavAddress ? 'width: 60%' : ''">
            <div class="col-12">
              <p v-show="editingFavAddress === fav.address" class="text-caption word-wrap"><small class="monospace">{{fav.address}}</small></p>
            </div>
            <div class="row align-center address-name-text" :style="!!editingFavAddress ? 'width: 100%' : ''">
              <p v-if="editingFavAddress !== fav.address && !!fav.name" class="text-md-body-2 text-caption">{{ fav.name }}</p>
              <p v-else-if="editingFavAddress !== fav.address && !fav.name" class="text-md-body-2 text-caption opacity-40"><i>NULL</i></p>
              <v-text-field @keypress.enter="saveFav" class="mt-5 mr-2" v-else v-model="editingFavName" density="compact" label="Edit name"></v-text-field>
            </div>
          </td>
          <td class="pa-0 address-id-text">
            <Tooltip v-if="!editingFavAddress" class="address-id-text" text="Click to copy">
              <p @click="clickToCopy" class="truncate monospace opacity-40">{{ fav.address }}</p>
            </Tooltip>
            <div class="col-12 centered">
              <v-btn @click="deleteFav" elevation="1" v-if="editingFavAddress === fav.address" color="transparent" text-color="error" icon flat><v-icon>{{ icons.deleteOutline }}</v-icon></v-btn>
            </div>
          </td>
          <td class="pa-0 table-icon-field">
            <div class="row mx-auto centered">
              <div v-if="!editingFavAddress" class="col-12 centered">
                <v-btn
                  @click="editingFavAddress = fav.address; editingFavName = fav.name; editingFavColor = fav.color"
                  color="transparent"
                  text-color="orange-darken-2"
                  icon flat>
                  <v-icon>{{ icons.pencil }}</v-icon>
                </v-btn>
              </div>
              <div v-if="editingFavAddress === fav.address" class="col-12 centered" style="height: 150px; width: 90px">
                <v-btn elevation="1" class="mb-10" color="transparent" @click="eraseEditingFav" flat><small>Cancel</small></v-btn>
                <v-btn elevation="1" color="transparent" @click="saveFav" flat><small>Save</small></v-btn>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </v-table>
      <v-divider></v-divider>
      <v-pagination
          class="mt-3 text-orange truncate"
          theme="default"
          size="small"
          v-model="currentPage"
          :length="numberOfPages"
          :total-visible="3"
          :disabled="isLoading"
      ></v-pagination>

    </div>
    <div class="col-12 pt-100 align-end">
      <div>
        <div style="max-width: 344px; min-width: 250px;" class="glass purple tilt-card-favourites no-select pointer mx-auto m-20" data-tilt>
          <div class="glass-content">
            <v-card
                @click="$router.push('/shop')"
                v-ripple
                color="transparent"
            >
              <v-card-actions>
                <v-btn color="transparent" text class="opacity-60 appear-up">Go to Shop</v-btn>
                <v-spacer></v-spacer>
                <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 pt-40 align-end">
      <div>
        <div style="max-width: 344px; min-width: 250px;" class="glass orange tilt-card-favourites no-select pointer mx-auto m-20" data-tilt>
          <div class="glass-content">
            <v-card
                @click="$router.push('/btc')"
                v-ripple
                color="transparent"
            >
              <v-card-actions>
                <v-btn color="transparent" text class="opacity-60 appear-up">Go to Bitcoin explorer</v-btn>
                <v-spacer></v-spacer>
                <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import Utils from "@/assets/js/Utils";
import { mdiChevronUp, mdiChevronDown, mdiArrowRight, mdiSortVariant, mdiDeleteOutline, mdiPencil } from "@mdi/js";

export default {
  name: "Favourites",
  components: { Tooltip },
  props: {
    loadedUserData: Boolean,
  },
  data() {
    return {
      icons: {
        up: mdiChevronUp,
        down: mdiChevronDown,
        sortVariant: mdiSortVariant,
        deleteOutline: mdiDeleteOutline,
        pencil: mdiPencil,
        arrowRight: mdiArrowRight,
      },
      currentPage: 1,
      itemsPerPage: 10,
      cryptoBtnArrow: 'down',
      colorBtnArrow: 'down',
      currCrypto: 'btc',
      sortField: 'name',
      sortAsc: true,
      editingFavAddress: null,
      editingFavName: null,
      editingFavColor: null,
      isLoadingOther: true,
      showColorMenu: false,
      availableColors: ['pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'yellow', 'amber', 'deep-orange', 'brown', 'blue-grey', 'grey'],
      metadata: {
        title: "Favourites",
        description: "Manage favourite blockchain addresses. Change color and set a nickname to make them more recognizable.",
      },
    }
  },
  computed: {
    isLoading() { return !this.$props.loadedUserData || this.isLoadingOther; },
    favourites() { return window.app?.userData?.favourites ? window.app.userData.favourites : {}; },
    parsedFavourites() {
      let newObj = {};
      Object.keys(this.favourites).forEach(fav=>{
        if(this.favourites[fav].crypto && this.favourites[fav].crypto === this.currCrypto) { newObj[fav] = this.favourites[fav]; }
      });
      return newObj;
    },
    favouritesArray() {
      let newArray = [];
      Object.keys(this.parsedFavourites).forEach(fav=>{
        let newObj = Utils.deepObjCopy(this.parsedFavourites[fav]);
        newObj.address = fav;
        newArray.push(newObj);
      });
      newArray.sort((a, b)=>{
        if(this.sortAsc) {
          return a[this.sortField]?.toLowerCase() < b[this.sortField]?.toLowerCase() ? -1 : 1;
        } else {
          return a[this.sortField]?.toLowerCase() > b[this.sortField]?.toLowerCase() ? -1 : 1;
        }
      });
      return newArray;
    },
    currentPageOffset() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    currentPagedFav() {
      return this.favouritesArray.slice(this.currentPageOffset, this.currentPage * this.itemsPerPage);
    },
    numberOfPages() {
      let wholePagesCount = Math.floor(this.favouritesArray.length / this.itemsPerPage);
      wholePagesCount += this.favouritesArray.length % this.itemsPerPage ? 1 : 0;
      return wholePagesCount;
    },
  },
  methods: {
    clickCurrCrypto(clickedCrypto) {
      if(!this.editingFavAddress) {
        this.currentPage = 1;
        this.currCrypto = clickedCrypto;
      }
    },
    handleColorClick(e, isClosing = false) {
      if(e && e?.stopPropagation) { e.stopPropagation(); }
      this.showColorMenu = isClosing ? false : !this.showColorMenu;
      this.colorBtnArrow = this.showColorMenu ? 'up' : 'down';
      if(this.showColorMenu) {
        document.addEventListener('click', this.quitColorMenu, {once: true});
      } else if(!this.showColorMenu) {
        document.removeEventListener('click', this.quitColorMenu);
      }
    },
    quitColorMenu() {
      this.handleColorClick(null, true);
    },
    clickColorBadge(color) {
      this.editingFavColor = color;
    },
    checkLogin(callback) {
      if(window?.app?.checkedLogin !== undefined && window?.app?.isLogged !== undefined) {
        if(!window.app.checkedLogin) {
          setTimeout(()=>{
            this.checkLogin(callback);
          }, 200);
        } else {
          callback(!!window.app.isLogged);
        }
      } else {
        callback(false);
      }

    },
    handleCryptoSelectClick(props, isClosing = false) {
      this.cryptoBtnArrow = isClosing ? 'down' : props.modelValue ? 'down' : 'up';
      if(!props.modelValue && !isClosing) {
        document.addEventListener('click', ()=>{
          this.handleCryptoSelectClick({}, true);
        }, {once: true});
      }
    },
    clickToCopy(e) {
      navigator.clipboard.writeText(e.currentTarget.innerText);
      let tooltip = e.currentTarget.parentElement.querySelector('.tooltiptext');
      if (tooltip) {
        let target = e.currentTarget;
        target.classList.add('text-orange', 'no-pointer-events');
        let oldText = tooltip.innerText;
        tooltip.innerText = 'Copied!';
        setTimeout(() => {
          target.classList.remove('text-orange');
        }, 50);
        setTimeout(() => {
          tooltip.innerText = oldText;
          target.classList.remove('no-pointer-events');
        }, 1000);
      }
    },
    clickColumn(field) {
      if(!this.editingFavAddress) {
        this.currentPage = 1;
        this.sortAsc = this.sortField === field ? !this.sortAsc : true;
        this.sortField = field;
      }
    },
    notUpdatedFav() {
      let fav = this.favourites[this.editingFavAddress];
      let editedFav = this.getAddingFav();
      return !!fav && fav.name === editedFav.name && fav.color === editedFav.color;
    },
    eraseEditingFav() {
      if(!this.showColorMenu) {
        this.editingFavAddress = null;
        this.editingFavName = null;
        this.editingFavColor = null;
      }
    },
    getItemPageNumber(address) {
      let itemPage = 1;
      let foundAddress = this.favouritesArray.find(addr => addr.address === address);
      if(foundAddress) {
        let index = this.favouritesArray.indexOf(foundAddress);
        itemPage = Math.floor(index / this.itemsPerPage)+1;
      }
      return itemPage;
    },
    saveFav() {
      if(!this.showColorMenu) {
        let updatingFav = this.getAddingFav();
        if(this.notUpdatedFav()) {
          this.eraseEditingFav();
        } else {
          this.isLoadingOther = true;
          if(window?.app?.updateFavouriteFromList) {
            window.app.updateFavouriteFromList(this.editingFavAddress, updatingFav, ()=>{
              this.isLoadingOther = false;
              this.currentPage = this.getItemPageNumber(this.editingFavAddress);
              this.eraseEditingFav();
            });
          }
        }
      }
    },
    deleteFav() {
      if(!this.showColorMenu) {
        this.isLoadingOther = true;
        if(window?.app?.deleteFavourite) {
          window.app.deleteFavourite(this.editingFavAddress, ()=>{
            this.isLoadingOther = false;
            this.eraseEditingFav();
            this.currentPage = 1;
          });
        }
      }
    },
    getAddingFav() { return Utils.deepObjCopy({ name: this.editingFavName, color: this.editingFavColor, crypto: this.currCrypto }); },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth", });
    this.checkLogin(isLogged=>{
      if(!isLogged) {
        this.$router.replace('/');
      } else {
        this.isLoadingOther = false;
      }
    });
    if(!!window.VanillaTilt) {
      window.VanillaTilt.init(document.querySelectorAll('.tilt-card-favourites'));
    }
  },
  created() {
    document.title = this.metadata.title;
    document.querySelector('meta[name="description"]').setAttribute("content", this.metadata.description);
  },
}
</script>

<style lang="scss" scoped>
th p{
  @media all and (max-width: 768px) {
    font-size: 12px;
  }
}
p.address-id-text {
  font-size: 14px;
  transition: color 1.5s ease;
  cursor: pointer;

  &.text-orange {
    opacity: .8;
    transition: none;
  }
  @media all and (max-width: 960px) {
    font-size: 12px;
  }
}
.address-name-text { max-width: 300px; }
.reversed-icon {
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}
.table-icon-field { width: 120px; }

@media all and (max-width: 720px){
  .address-id-text { max-width: 200px; }
}
@media all and (max-width: 450px){
  .address-id-text { max-width: 150px; }
  .address-name-text { max-width: 150px; }
  .table-icon-field { width: 80px; }
}
@media all and (max-width: 400px){
  .address-id-text { max-width: 90px; }
  .table-icon-field { width: 50px; }
}
@media all and (max-width: 350px){
  .address-name-text { max-width: 120px; }
}
</style>