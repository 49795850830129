<template>
  <router-link :to="link" class="no-line">
    <v-card
        v-ripple
        color="transparent"
        width="250"
        min-height="220"
        class="tilt-card"
        data-tilt-max="2"
        data-tilt-glare
        data-tilt-full-page-listening
        data-tilt-max-glare="0.3"
    >
      <v-card-title class="mt-2 text-orange">
        <v-avatar size="large" :icon="mainIcon"></v-avatar>
        <p class="ml-3">{{ cardTitle }}</p>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="no-pointer-events">
        <v-list density="compact">
          <v-list-item v-for="phrase in phrases">
            <v-list-item-avatar left><v-icon :icon="icons.circle"></v-icon></v-list-item-avatar>
            <v-list-item-subtitle v-text="phrase"></v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </router-link>
</template>

<script>
import {mdiCircleSmall, mdiChevronRight} from "@mdi/js";

export default {
  name: "FeaturesCard",
  props: {
    mainIcon: String,
    cardTitle: String,
    phrases: Array,
    link: String,
  },
  data() {
    return {
      icons: {
        circle: mdiCircleSmall,
        chevronRight: mdiChevronRight,
      },
    }
  },
}
</script>

<style scoped>

</style>