const AsyncScriptLoader = {
  URL_GTAG: "https://www.googletagmanager.com/gtag/js?id=",
  loadSingleResource(url, callback = null) {
    var script = document.createElement("script");
    script.src = url;
    document.body.appendChild(script);
    if (callback) {
      script.onload = () => {
        callback();
      };
    }
  },
  loadGtag(measurement_id, callback) {
    if (window.isLoadedGtag) {
		callback();
    } else if(!window.isLoadingGtag) {
		window.isLoadingGtag = true;
		this.loadSingleResource(this.URL_GTAG+measurement_id, () => {
			window.isLoadedGtag = true;
			callback();
		});
    }
  },
};
export default AsyncScriptLoader;
