<template>
  <div>
    <v-card :class="isLoading ? 'no-pointer-events' : ''" ref="cardContainer" class="result-card mx-auto pt-3 glassed" :max-width="showTransactionsTable ? 960 : 640">
      <v-list class="transparent pl-2">
        <v-card-subtitle class="opacity-100">
          <div class="row">
            <div style="max-width: calc(100% - 40px)">
              <p class="monospace truncate opacity-60">{{ $props.address }}</p>
              <p :class="'text-'+(currentFavColor ? currentFavColor : 'white')" class="text-caption opacity-40">{{ currentFavName }}</p>
            </div>
            <div class="col-shrink mobile-12">
              <v-menu offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn
                      flat
                      @click="clickAddToFavourites(props)"
                      :text-color="currentFavColor ? currentFavColor : 'orange'"
                      :class="!isLogged ? 'no-pointer-events opacity-20' : ''"
                      v-bind="props"
                      color="transparent"
                      ref="favouriteBtn"
                      :icon="isFavourite ? icons.star : icons.starOutline"
                  ></v-btn>
                </template>
                <v-list color="#2d2d2d" style="overflow-y: auto; padding-bottom: 0;" elevation="2" rounded max-width="400" max-height="500" width="300">
                  <v-list-item min-height="20" class="py-0"><p class="text-orange">Add to favourites</p></v-list-item>
                  <v-list-item>
                    <div class="col-12">
                      <div class="row">
                        <v-col cols="12">
                          <v-text-field
                              density="compact"
                              label="Address"
                              :model-value="$props.address"
                              disabled
                          ></v-text-field>
                          <v-text-field ref="favouriteNameInput" @keypress.enter="clickSaveFavourite" v-model="addingFavName" density="compact" label="Name"></v-text-field>
                        </v-col>
                      </div>
                      <div class="row">
                        <v-menu offset-y>
                          <template v-slot:activator="{ props }">
                            <div class="row">
                              <v-badge :color="addingFavColor" inline></v-badge>
                              <v-spacer></v-spacer>
                              <v-btn
                                  flat
                                  color="transparent"
                                  text-color="orange"
                                  v-bind="props"
                                  ref="colorBtn"
                                  @click="handleColorClick(props)"
                              >
                                <p>Color</p>
                                <v-icon>{{ icons[colorBtnArrow] }}</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <v-list color="#2d2d2d" elevation="2" rounded max-width="150" width="112">
                            <v-badge @click="clickColorBadge(color)" class="pointer" v-for="(color, index) in availableColors" :key="index" inline :color="color"></v-badge>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="clickSaveFavourite"
                      flat
                      color="transparent"
                      text-color="orange"
                    >Save</v-btn>
                  </v-card-actions>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-card-subtitle>
        <v-list-item>
          <div class="col-12">
            <p class="text-overline text-orange opacity-60 no-select">Final balance</p>
            <p class="text-h4 text-md-h3 word-wrap">{{ bigBalance }}</p>
            <p class="text-caption opacity-25">{{ price }}</p>
          </div>
        </v-list-item>
        <v-list-item>
          <div class="col-12">
            <div class="row align-center">
              <p class="mr-auto text-subtitle-1">{{ smallBalance }}</p>
              <v-btn title="Switch" @click="switchMainFiat" icon flat text-color="orange" color="transparent">
                <v-icon>{{ icons.swapVertical }}</v-icon>
              </v-btn>
            </div>
            <div v-if="totalTransacted" class="row">
              <div class="col-12">
                <p><small class="opacity-40">Total spent: </small> <small>{{ totalSpent }}</small></p>
                <p><small class="opacity-40">Total received: </small> <small>{{ totalReceived }}</small></p>
              </div>
            </div>
          </div>
        </v-list-item>

      </v-list>
      <v-card-text class="pl-5">
        <div class="row centered flex-start" v-show="!!confirmedTxs || (!balance)">
          <v-icon class="mr-2" title="Total transactions">{{ icons.swapHorizontal }}</v-icon>
          <v-list-item-title title="Total transactions" class="text-button cursor-default">{{ confirmedTxs }} <p class="text-overline d-inline">Confirmed</p></v-list-item-title>
        </div>
        <div class="row centered flex-start" v-show="!!unconfirmedTxs">
          <v-icon color="amber" title="Unconfirmed transactions" class="mr-2">{{ icons.timer }}</v-icon>
          <v-list-item-title title="Unconfirmed transactions" class="text-button cursor-default text-amber">{{ unconfirmedTxs }} <p class="text-overline d-inline">Pending</p></v-list-item-title>
        </div>
      </v-card-text>
      <transition name="gain-height">
        <TransactionsTable
            @fetch-paged-txs="fetchPagedTxs"
            @fetch-detail-tx="fetchDetailTx"
            :address="address"
            :total-txs="confirmedTxs + unconfirmedTxs"
            :is-usd="isUsd"
            :is-conventional="isConventional"
            :main-fiat="mainFiat"
            :curr-crypto="currCrypto"
            v-show="showTransactionsTable"
            ref="table"
            class="mt-20 mb-10"
        />
      </transition>
      <v-divider></v-divider>
      <v-progress-linear
          v-show="isLoading"
          class="opacity-60"
          height="2"
          indeterminate
      ></v-progress-linear>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="transparent" v-show="!!confirmedTxs || !!unconfirmedTxs || !!balance" @click="clickShowTransactions" :class="isLoading ? 'disabled' : ''" ref="show-transactions-button" text text-color="orange">
          <p class="text-button">{{ showTransactionsTable ? 'Hide transactions' : 'Show transactions' }}</p>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Utils from "@/assets/js/Utils";
import TransactionsTable from '@/components/TransactionsTable';
import Firebase from '@/assets/js/Firebase';
import Gtag from "@/assets/js/Gtag";
import { mdiChevronUp, mdiChevronDown, mdiStar, mdiTimerSandEmpty, mdiStarOutline, mdiSwapVerticalBold, mdiSwapHorizontal } from "@mdi/js";

export default {
  components: {TransactionsTable},
  props: {
    currCrypto: {type: Object},
    isUsd: {type: Boolean},
    isConventional: {type: Boolean},
    address: {type: String, default: ''},
    balance: {type: Number, default: 0},
    fiatBalance: {type: String, default: '$0'},
    cryptoBalance: {type: String, default: '0 BTC'},
    price: {type: String, default: '0 BTC'},
    confirmedTxs: {type: Number, default: 0},
    unconfirmedTxs: {type: Number, default: 0},
    totalTransacted: {type: Object, default: null},
  },
  data() {
    return {
      icons: {
        up: mdiChevronUp,
        down: mdiChevronDown,
        timer: mdiTimerSandEmpty,
        star: mdiStar,
        starOutline: mdiStarOutline,
        swapVertical: mdiSwapVerticalBold,
        swapHorizontal: mdiSwapHorizontal,
      },
      showTransactionsTable: false,
      mainFiat: true,
      isLoading: false,
      fetchedTransactions: null,
      availableColors: ['pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'yellow', 'amber', 'deep-orange', 'brown', 'blue-grey', 'grey'],
      addingFavName: '',
      addingFavColor: 'orange-darken-2',
      colorBtnArrow: 'down',
    }
  },
  computed: {
    bigBalance() { return this.mainFiat ? this.$props.fiatBalance : this.$props.cryptoBalance },
    smallBalance() { return this.mainFiat ? this.$props.cryptoBalance : this.$props.fiatBalance },
    totalSpent() { return this.totalTransacted ? (this.mainFiat ? this.totalTransacted.spentFiat : this.totalTransacted.spentCrypto): null},
    totalReceived() { return this.totalTransacted ? (this.mainFiat ? this.totalTransacted.receivedFiat : this.totalTransacted.receivedCrypto): null},
    isFavourite() { return !!this.getCurrentFav },
    currentFavColor() { return !!this.getCurrentFav && this.getCurrentFav.color ? this.getCurrentFav.color : null },
    currentFavName() { return !!this.getCurrentFav && this.getCurrentFav.name ? this.getCurrentFav.name : null },
    getCurrentFav() {
      let returningFav = null;
      if(window?.app?.getFavouriteFromAddress) {
        returningFav = window.app.getFavouriteFromAddress(this.$props.address);
      }
      return returningFav;
    },
    isLogged() { return !!window && !!window?.app?.isLogged; },
  },
  methods: {
    handleColorClick(props, isClosing = false) {
      this.colorBtnArrow = isClosing ? 'down' : props.modelValue ? 'down' : 'up';
      if(!props.modelValue && !isClosing) {
        document.addEventListener('click', ()=>{
          this.handleColorClick({}, true);
        }, {once: true});
      }
    },
    clickShowTransactions() {
      if(this.fetchedTransactions) {
        this.openTransactionTable();
      } else {
        this.$emit('fetchTxs', true);
      }
    },
    fetchPagedTxs() {
      this.$emit('fetchPagedTxs', true);
    },
    fetchDetailTx(tx) {
      this.$emit('fetchDetailTx', tx);
    },
    importTransactions(list){
      this.fetchedTransactions = list;
      this.$refs.table.txs = list;
      this.isLoading = false;
    },
    openTransactionTable() {
      if(!this.showTransactionsTable) {
        Gtag.sendAnalytic("Show transactions", this.$props.currCrypto.symbol, this.$props.address);
      }
      this.showTransactionsTable = !this.showTransactionsTable
      Utils.scrollToElem(this.$refs.table.$el, 150);
    },
    resetTxData(){
      this.showTransactionsTable = false;
      this.fetchedTransactions = null;
      this.$refs.table.resetTxs();
    },
    switchMainFiat() {
      this.mainFiat = !this.mainFiat;
      Utils.setStorageItem('isMainFiat', this.mainFiat ? '1':'0');
      Firebase.init().updateMainFiat(this.mainFiat);
    },
    clickAddToFavourites(props) {
      if(!props.modelValue) {
        document.activeElement.blur();
        this.resetAddingFavFields();
      }
    },
    clickSaveFavourite() {
      this.isLoading = true;
      this.$refs.favouriteBtn.$el.click();
      let updatingFav = this.getAddingFav();
      if(window?.app?.updateFavouriteCard) {
        window.app.updateFavouriteCard(this.address, updatingFav);
      }
    },
    clickColorBadge(color) {
      this.addingFavColor = color;
      this.$refs.colorBtn.$el.click();
    },
    resetAddingFavFields() {
      if(window?.app) {
        if(window.app.checkedLogin && window.app.isLogged) {
          this.addingFavColor = this.currentFavColor ? this.currentFavColor : 'orange-darken-2';
          this.addingFavName = this.currentFavName ? this.currentFavName : '';
        } else if(!window.app.checkedLogin) {
          setTimeout(()=>{
            this.resetAddingFavFields();
          }, 600);
        }
      }
    },
    getAddingFav() {
      let returningObj = { name: this.addingFavName, color: this.addingFavColor, crypto: this.$route.path.substr(1,3) };
      return Utils.deepObjCopy(returningObj);
    },
  },
  mounted() {
    let storageMainFiat = Utils.getStorageItem('isMainFiat');
    if(storageMainFiat) {
      this.mainFiat = storageMainFiat === '1';
    }
  },
}
</script>
<style lang="scss">
 .result-card {
   transition: max-width .5s ease-out;
 }
</style>