const Utils = {
	coloredLog(phrase) {
		console.log(
			"%c" + phrase,
			"font-weight: bold;font-size: 30px; background:  linear-gradient(0deg, rgba(230,100,101,1) 0%, rgba(94,98,126,1) 39%, rgba(2,70,103,1) 100%); color: #bada55"
		);
	},
	createElementFromHTML(htmlString) {
		var div = document.createElement("div");
		div.innerHTML = htmlString.trim();

		// Change this to div.childNodes to support multiple top-level nodes
		return div.firstChild;
	},
	convertHTML(str) {
		if (!str) {
			return "";
		}
		let replacements = {
			"&": "&amp;",
			"<": "&lt;",
			">": "&gt;",
			'"': "&quot;",
			"'": "&apos;",
			"<>": "&lt;&gt;",
		};
		return str.replace(/(&|<|>|"|'|<>)/gi, function (noe) {
			return replacements[noe];
		});
	},
	deepObjCopy(refObj) {
		let self = this;
		if (!(refObj instanceof Object) || Object.keys(refObj).length < 1) {
			return refObj instanceof Array ? [] : {};
		}
		var result = refObj instanceof Array ? [] : {};
		for (var key in refObj) {
			if (refObj[key] instanceof Object) {
				if (refObj[key] instanceof Array) {
					result[key] = [];
					refObj[key].forEach(function (item) {
						if (item instanceof Object) {
							result[key].push(self.deepObjCopy(item));
						} else {
							result[key].push(item);
						}
					});
				} else {
					result[key] = self.deepObjCopy(refObj[key]);
				}
			} else {
				result[key] = refObj[key];
			}
		}
		return result;
	},
	sameArrayItems(array1, array2) {
		return array1.sort().join(",") === array2.sort().join(",");
	},
	getArraysCommonElements(arr1, arr2) {
		return arr1.filter(function (n) {
			return arr2.indexOf(n) !== -1;
		});
	},
	syntaxHighlight(json) {
		json = json
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;");
		return json.replace(
			/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
			function (match) {
				var cls = "number";
				if (/^"/.test(match)) {
					if (/:$/.test(match)) {
						cls = "key";
					} else {
						cls = "string";
					}
				} else if (/true|false/.test(match)) {
					cls = "boolean";
				} else if (/null/.test(match)) {
					cls = "null";
				}
				return '<span class="' + cls + '">' + match + "</span>";
			}
		);
	},
	parseObjectForPrinting(obj) {
		return this.syntaxHighlight(JSON.stringify(obj, null, 3));
	},
	acceptedCookies() {
		return (
			window?.localStorage !== undefined &&
			window.localStorage.getItem("accepted-cookies") === "1"
		);
	},
	dismissedBanner() {
		return (
			window?.localStorage !== undefined &&
			!!window.localStorage.getItem("accepted-cookies")
		);
	},
	checkRouteForTransition(component, to, from, forceBack = false) {
		const toDepth = to.path === "/" ? 1 : to.path.split("/").length;
		const fromDepth = from.path === "/" ? 1 : from.path.split("/").length;
		component.transitionName = toDepth < fromDepth || forceBack ? "slide-right" : "slide-left";
	},
	scrollToElem(container, timeO = 10) {
		if (
			window?.document?.body?.getBoundingClientRect &&
			container?.getBoundingClientRect
		) {
			setTimeout(() => {
				var bodyRect = window.document.body.getBoundingClientRect();
				var elemRect = container.getBoundingClientRect();
				var offset = elemRect.top - bodyRect.top - 100;
				window.scrollTo({
					top: offset,
					behavior: "smooth",
				});
			}, timeO);
		}
	},
	truncateMiddle(string) {
		let returningStr = '';
		if (string && typeof string === 'string' && string.length > 10) {
			returningStr = string.slice(0,6)+'...'+string.slice(-3);
		}
		return returningStr;
	},
	getStorageItem(item) {
		let returningValue = null;
		if (this.acceptedCookies() && localStorage.getItem(item)) {
			returningValue = localStorage.getItem(item);
		}
		return returningValue;
	},
	setStorageItem(item, value) {
		if (this.acceptedCookies()) {
			window.localStorage.setItem(item, value);
		}
	},
	getRndInteger(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	},
	capitalizeFirstLetter(str) {
		return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
	},
};

export default Utils;
