const Cryptos = {

	btc: {
		name: "Bitcoin",
		symbol: "BTC",
		placeholder: "bc1000aaa000aaa000aaa000",
		fn: "getBtcAddress",
		addresTxsFn: "getBtcAddressTxs",
		txDetailFn: "getBtcTx",
		decimals: 8,
		unit: "sat",
		eurPrice: 36000,
		usdPrice: 43000,
		coinMarketCapId: 1,
		validResp(rawResp) {
			return (
				rawResp?.body?.address !== undefined &&
				rawResp?.body?.chain_stats?.funded_txo_sum !== undefined &&
				rawResp?.body?.chain_stats?.spent_txo_sum !== undefined
			);
		},
		parseRespData(rawResp) {
			let finalObj = { address: "", balance: 0, totalTxs: 0 , unconfirmedBalance: 0 , unconfirmedTotalTxs: 0 };
			if (this.validResp(rawResp)) {
				finalObj.address = rawResp.body.address;
				finalObj.balance =
					Number(rawResp.body.chain_stats.funded_txo_sum) -
					Number(rawResp.body.chain_stats.spent_txo_sum);
				if (
					rawResp.body.mempool_stats?.funded_txo_sum !== undefined &&
					rawResp.body.mempool_stats?.spent_txo_sum !== undefined
				) {
					finalObj.unconfirmedBalance =
						Number(rawResp.body.mempool_stats.funded_txo_sum) -
						Number(rawResp.body.mempool_stats.spent_txo_sum);
				}
				if (
					rawResp.body.chain_stats?.funded_txo_sum !== undefined &&
					rawResp.body.mempool_stats?.funded_txo_sum !== undefined &&
					rawResp.body.chain_stats?.spent_txo_sum !== undefined &&
					rawResp.body.mempool_stats?.spent_txo_sum !== undefined
				) {
					finalObj.totalSpent =
						Number(rawResp.body.chain_stats.spent_txo_sum) +
						Number(rawResp.body.mempool_stats.spent_txo_sum);
					finalObj.totalReceived =
						Number(rawResp.body.chain_stats.funded_txo_sum) +
						Number(rawResp.body.mempool_stats.funded_txo_sum);
				}
				if (
					rawResp.body.chain_stats?.funded_txo_count !== undefined &&
					rawResp.body.chain_stats?.spent_txo_count !== undefined &&
					rawResp.body.mempool_stats?.funded_txo_count !== undefined &&
					rawResp.body.mempool_stats?.spent_txo_count !== undefined
				) {
					finalObj.totalTxs =
						Number(rawResp.body.chain_stats.funded_txo_count) +
						Number(rawResp.body.chain_stats.spent_txo_count);
					finalObj.unconfirmedTotalTxs =
						Number(rawResp.body.mempool_stats.funded_txo_count) +
						Number(rawResp.body.mempool_stats.spent_txo_count);
				}
			}
			return finalObj;
		},
		validAddressTxsResp(rawResp) { return rawResp?.body !== undefined && Array.isArray(rawResp?.body); },
		parseAddressTxsRespData(address, rawResp) {
			let finalObj = [];
			if (this.validAddressTxsResp(rawResp)) {
				let bodyResp = rawResp.body;
				if(bodyResp.length > 0) {
					bodyResp.forEach(tx => {
						tx.vout.forEach(vout=>{
							if(vout.scriptpubkey_address === address) {
								finalObj.push({value: vout.value, txId: tx.txid, confirmed: tx.status.confirmed, date: new Date(tx.status.block_time * 1000)});
							}
						});
						tx.vin.forEach(vin=>{
							if(!vin.is_coinbase && vin.prevout.scriptpubkey_address === address) {
								finalObj.push({value: -vin.prevout.value, txId: tx.txid, confirmed: tx.status.confirmed, date: new Date(tx.status.block_time * 1000)});
							}
						});
					});
				}
			}
			return finalObj;
		},
		fetchPagedTxs(address, offset, last_seen_txid) {
			return {
				apiFn: 'getBtcAddressTxsPaged', data: last_seen_txid
			}
		},
		validTxDetailResp(rawResp) {
			return (
				rawResp?.body?.txid !== undefined &&
				rawResp?.body?.vin !== undefined && Array.isArray(rawResp?.body?.vin) &&
				rawResp?.body?.vout !== undefined && Array.isArray(rawResp?.body?.vout) &&
				rawResp?.body?.fee !== undefined && rawResp?.body?.weight !== undefined &&
				rawResp?.body?.status !== undefined && rawResp?.body?.status?.confirmed !== undefined
			);
		},
		parseTxDetailData(rawResp) {
			let finalObj = null;
			if(this.validTxDetailResp(rawResp)) {
				let bodyResp = rawResp.body;
				finalObj = { id: bodyResp.txid, ins: [], outs: [], totalMoved: 0, fee: bodyResp.fee, feeRate: bodyResp.fee/(bodyResp.weight/4), block: bodyResp.status.confirmed ? {hash: bodyResp.status.block_hash, number: bodyResp.status.block_height} : null, date: new Date(bodyResp.status.block_time * 1000), confirmed: bodyResp.status.confirmed };
				bodyResp.vout.forEach(output => {
					if(output.scriptpubkey_type !== 'op_return') {
						finalObj.totalMoved += output.value;
						finalObj.outs.push({value: output.value, address: output.scriptpubkey_address, addressType: output.scriptpubkey_type});
					}
				});
				bodyResp.vin.forEach(input => {
					finalObj.ins.push({isCoinbase: !!input.is_coinbase, value: !!input.is_coinbase ? 0 : input.prevout.value, address: !!input.is_coinbase ? null : input.prevout.scriptpubkey_address, addressType: !!input.is_coinbase ? null : input.prevout.scriptpubkey_type});
				});
			}
			return finalObj;
		},
	},

	sol: {
		name: "Solana",
		symbol: "SOL",
		placeholder: "9LC426VbNywjPjXb6JjxSNzSAZgM",
		fn: "getSolAccount",
		addresTxsFn: 'getSolAccountTxs',
		decimals: 9,
		unit: "lamp",
		eurPrice: 120,
		usdPrice: 150,
		coinMarketCapId: 5426,
		validResp(rawResp) {
			return (
				rawResp?.body?.type !== undefined &&
				rawResp?.body?.value?.base?.address?.address !== undefined &&
				rawResp?.body?.value?.base?.balance !== undefined
			);
		},
		parseRespData(rawResp) {
			let finalObj = { address: "", balance: 0, totalTxs: 0 , unconfirmedBalance: 0 , unconfirmedTotalTxs: 0 };
			if (this.validResp(rawResp)) {
				let resp = rawResp?.body?.value?.base;
				finalObj.address = rawResp.body.value.base.address.address;
				finalObj.balance = Number(resp.balance);
			}
			return finalObj;
		},
		validAddressTxsResp(rawResp) { return rawResp?.body !== undefined && Array.isArray(rawResp?.body); },
		parseAddressTxsRespData(address, rawResp) {
			let finalObj = [];
			if (this.validAddressTxsResp(rawResp)) {
				let bodyResp = rawResp.body;
				if(bodyResp.length > 0) {
					bodyResp.forEach(tx => {
						let addingItem = {value: 0, txId: tx.transactionHash, confirmed: tx.valid, date: new Date(tx.blocktime.absolute * 1000)};
						let instruction = tx.instructions.find(inst => { return inst.hasOwnProperty('parsed') && inst.parsed.hasOwnProperty('Transfer') });
						if(instruction) {
							let transfer = instruction.parsed.Transfer;
							addingItem.value = addingItem.account === address ? 0 - transfer.lamports : transfer.lamports;
						} else {
							addingItem.details = tx?.mostImportantInstruction?.name ? tx.mostImportantInstruction.name : 'unknown';
						}
						if(!addingItem.confirmed) { addingItem.failed = true; }
						finalObj.push(addingItem);
					});
				}
			}
			return finalObj;
		},
		fetchPagedTxs(address, offset, last_seen_txid) {
			return {
				apiFn: 'getSolAccountTxsPaged', data: offset
			}
		},
	},
};

export default Cryptos;
