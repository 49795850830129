<template>
  <v-app :class="isLoading ? 'no-pointer-events' : ''" :theme="isDark ? 'dark' : 'light'">
    <BackgroundBalls :is-dark="isDark"></BackgroundBalls>
    <v-app-bar app>
      <v-progress-linear
          indeterminate
          :active="isLoading"
          style="position:absolute;top: 0;left: 0"
          color="orange"
      ></v-progress-linear>
      <v-btn icon @click.stop="drawer = !drawer"><v-icon>{{ menuIcon }}</v-icon></v-btn>
      <v-spacer></v-spacer>
      <div @click.stop="drawer = !drawer" v-if="isLogged && profileImg" :title="'Logged in as '+userEmail" class="pointer image-container centered mr-20" style="border: 1px solid #ff9800; height: 50px; width: 50px; overflow: hidden; border-radius: 50%">
        <object :data="profileImg" type="image/png">
          <img
              rel="noreferrer"
              max-height="50"
              max-width="50"
              height="50"
              width="50"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="
              :alt="userEmail+' profile image'"
          >
        </object>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary app>
      <v-list>
        <v-list-item v-show="!$route.path.startsWith('/favourites')">
          <div class="row flex-start pa-0 pt-20 pb-0">
            <div><v-btn to="/favourites" @click="drawer = false" :class="isLogged ? '' : 'disabled'" flat>Favourites <v-icon>{{ icons.chevronRight }}</v-icon></v-btn></div>
            <div v-if="!isLogged"><p class="text-caption opacity-60">Log in to access favourites and more</p></div>
          </div>
        </v-list-item>
        <v-list-item v-show="!$route.path.startsWith('/btc') && !$route.path.startsWith('/sol')">
          <div class="row flex-start pa-0 pt-0 pb-20">
            <div><v-btn to="/btc" @click="drawer = false" flat>Explorer <v-icon>{{ icons.chevronRight }}</v-icon></v-btn></div>
          </div>
        </v-list-item>
        <v-list-item v-show="!$route.path.startsWith('/shop')">
          <div class="row flex-start pa-0 pt-0 pb-20">
            <div><v-btn to="/shop" @click="drawer = false" flat>Shop <v-icon>{{ icons.chevronRight }}</v-icon></v-btn></div>
          </div>
        </v-list-item>
        <v-list-item v-show="!$route.path.startsWith('/verify')">
          <div class="row flex-start pa-0 pt-0 pb-20">
            <div><v-btn to="/verify" @click="drawer = false" flat>Signature verifier <v-icon>{{ icons.chevronRight }}</v-icon></v-btn></div>
          </div>
        </v-list-item>
        <v-list-item>
          <div class="row flex-end pt-20">
            <v-btn :disabled="!checkedLogin" :class="checkedLogin ? '':'disabled'" @click="clickLogBtn" v-text="isLogged ? 'Logout' : 'Login'" flat text-color="orange"></v-btn>
          </div>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item>
          <div class="col-12">
            <v-switch
                v-model="isDark"
                label="Toggle dark mode"
                hide-details
                class="mt-auto"
                color="primary"
                @change="toggleDarkMode"
            ></v-switch>
            <v-list-item-subtitle class="text-caption text-align-right ml-auto">
              Vuetify Explorer is provided by <v-btn flat size="small" text-color="orange" href="https://ggstudio.io" target="_blank">ggstudio</v-btn>
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <v-main style="min-height: calc(100% - 150px)">
      <v-system-bar window dark >
        <transition :name="transitionName" mode="out-in">
          <router-link class="no-line ml-2" v-show="$route.path !== '/'" to="/">
            <v-icon class="ml-2">{{ icons.home }}</v-icon>
          </router-link>
        </transition>
        <v-spacer></v-spacer>
      </v-system-bar>
      <transition v-show="showLoginMessage" name="gain-height">
        <v-alert
            style="max-width: 300px; position: absolute; right: 0; z-index: 1"
            v-model="showLoginMessage"
            border="start"
            class="ma-2 pointer ml-auto glassed"
            :text-color="isLogged ? 'orange' : 'error'"
            @click="showLoginMessage = false"
        >
          <p class="text-h6 text-align-center" style="width: 100%">{{isLogged ? 'Authenticated' : 'Authentication aborted'}}</p>
        </v-alert>
      </transition>
      <div class="col-12 mx-auto" style="position: relative; max-width: 960px;">
        <v-container>
          <router-view v-slot="{ Component }">
            <transition :name="transitionName" mode="out-in">
              <component :loaded-user-data="loadedUserData" @delete-address-from-history="deleteAddressFromHistory" @add-address-history="addAddressHistory" ref="currentView" :is="Component"/>
            </transition>
          </router-view>
        </v-container>
      </div>
    </v-main>
    <Popup :title="popupTitle" :message="popupMessage" ref="loginErrorPopup"></Popup>
    <PulseContainer :active="activePulseOverlay"></PulseContainer>
    <Footer></Footer>
    <v-expand-transition>
      <v-banner :theme="isDark ? 'light' : 'dark'" rounded v-show="banner" bottom position="fixed">
        <div class="col-8">
          <p class="text-subtitle-1 font-weight-bold">We use cookies</p>
          <p class="text-body-1">This site uses cookies for performance and
            <a href="https://policies.google.com/privacy" target="_blank">Google Analytics.</a>
          </p>
        </div>
        <div class="col-4">
          <div class="row flex-end mt-auto">
            <v-btn class="ml-1 mb-2" text flat text-color="orange" @click="()=>{acceptedCookies(true)}"> ACCEPT </v-btn>
            <v-btn class="ml-1" text flat @click="()=>{acceptedCookies(false)}"> Refuse </v-btn>
          </div>
        </div>
      </v-banner>
    </v-expand-transition>
  </v-app>
</template>

<script>

window.processDebugToken = process.env.VUE_APP_DEBUG_TOKEN;

import Utils from "@/assets/js/Utils";
import Firebase from "@/assets/js/Firebase";
import Footer from "@/components/Footer";
import Popup from "@/components/Popup";
import PulseContainer from "@/components/PulseContainer";
import BackgroundBalls from "@/components/BackgroundBalls";
import { mdiMenu, mdiMenuOpen, mdiChevronRight, mdiHome } from "@mdi/js";

export default {
  name: 'App',
  components: { Footer, Popup, PulseContainer, BackgroundBalls },
  data: () => ({
    icons: {
      menu: mdiMenu,
      manuOpen: mdiMenuOpen,
      chevronRight: mdiChevronRight,
      home: mdiHome,
    },
    checkedLogin: false,
    isLoading: true,
    activePulseOverlay: true,
    isLogged: false,
    profileImg: null,
    userEmail: null,
    banner: false,
    drawer: false,
    isDark: true,
    transitionName: "slide-left",
    isConventional: true,
    isUsd: true,
    popupMessage: "An error occurred while fetching resources. Please retry or refresh the page.<br><br><code>Error #008</code>",
    popupTitle: "Something went wrong",
    showLoginMessage: false,
    loadedUserData: false,
    userData: Utils.deepObjCopy(Firebase.init().userData),
  }),
  computed: {
    menuIcon() { return this.drawer ? this.icons.manuOpen : this.icons.menu; },
  },
  methods: {
    acceptedCookies(accepted) {
      if (window?.localStorage) {
        let storageValue = accepted ? "1" : "0";
        window.localStorage.setItem("accepted-cookies", storageValue);
      }
      this.banner = false;
    },
    login() {
      this.isLoading = true;
      this.activePulseOverlay = true;
      Firebase.init().loginRedirect(loginResp=> {
        if(loginResp) {
          this.getUserData();
        } else {
          this.handleNoLogin(true);
        }
      }, ()=>{
        this.handleNoLogin();
        this.$refs.loginErrorPopup.isOpen = true;
      });
    },
    logout() {
      this.isLoading = true;
      Firebase.init().logout();
    },
    clickLogBtn() {
      if(this.isLogged) {
        this.logout();
      } else {
        this.login();
      }
    },
    initUserData(data) {
      if(data.hasOwnProperty('darkMode')) { this.isDark = data.darkMode; }
      if(data.hasOwnProperty('isUsd')) { this.$refs.currentView.isUsd = data.isUsd; }
      if(data.hasOwnProperty('isConventional')) { this.$refs.currentView.isConventional = data.isConventional; }
      if(data.hasOwnProperty('mainFiat') && this.$refs.currentView?.$refs?.card) { this.$refs.currentView.$refs.card.mainFiat = data.mainFiat; }
      this.userData = data;
      this.loadedUserData = true;
      this.showAlert();
    },
    getUserData() {
      Firebase.init().checkLogin(isLogged=>{
        this.activePulseOverlay = false;
        this.checkedLogin = true;
        this.isLogged = isLogged;
        if(isLogged) {
          Firebase.init().getUserData(resp=>{
            this.initUserData(resp);
            this.isLoading = false;
            this.profileImg = Firebase.init().userImage ? Firebase.init().userImage : null;
            this.userEmail = Firebase.init().userEmail ? Firebase.init().userEmail : null;
          }, ()=>{ this.handleNoLogin(); });
        } else { this.handleNoLogin(); }
      });
    },
    handleNoLogin(aborted = false) {
      this.activePulseOverlay = false;
      this.isLoading = false;
      if(aborted) {
        this.showAlert();
      }
    },
    showAlert() {
      this.drawer = false;
      this.showLoginMessage = true;
      setTimeout(()=>{
        this.showLoginMessage = false;
      }, 6000);
    },
    toggleDarkMode() {
      this.userData.darkMode = this.isDark;
      Firebase.init().updateDarkMode(this.isDark);
    },
    addAddressHistory(address) {
      if(this.checkedLogin && this.isLogged) {
        if(this.userData?.addressesHistory) {
          let foundAddress = this.userData.addressesHistory.find(addr=>{ return addr.address === address.address });
          if(!foundAddress) { this.userData.addressesHistory.push(address); }
          Firebase.init().updateAddressesHistory(address);
        }
      } else if(!this.checkedLogin) {
        setTimeout(()=>{
          this.addAddressHistory(address);
        }, 600);
      }
    },
    deleteAddressFromHistory(address) {
      if(this.checkedLogin && this.isLogged) {
        if(this.userData?.addressesHistory) {
          let foundAddress = this.userData.addressesHistory.find(addr=>{ return addr.address === address.address });
          if(foundAddress) {
            let index = this.userData.addressesHistory.indexOf(foundAddress);
            this.userData.addressesHistory.splice(index, 1);
          }
          Firebase.init().updateAddressesHistory(address, false);
        }
      } else if(!this.checkedLogin) {
        setTimeout(()=>{
          this.deleteAddressFromHistory(address);
        }, 600);
      }
    },
    getFavouriteFromAddress(address) {
      let foundFav = null;
      if(address && this?.userData?.favourites && this?.userData?.favourites?.[address]) {
        foundFav = this.userData.favourites[address];
      }
      return foundFav;
    },
    updateFavouriteCard(address, body) {
      if(this?.userData?.favourites !== undefined && this.userData.favourites[address] !== body && this.$refs.currentView?.$refs?.card) {
        this.$refs.currentView.$refs.card.isLoading = true;
        if(!this.userData.favourites) { this.userData.favourites = {}; }
        this.userData.favourites[address] = body;
        Firebase.init().updateSingleFavourite(address, body, ()=>{
          this.$refs.currentView.$refs.card.isLoading = false;
          this.$refs.currentView.$refs.card.resetAddingFavFields();
        });
      }
    },
    updateFavouriteFromList(address, body, callback) {
      if(this?.userData?.favourites !== undefined) {
        if(!this.userData.favourites) { this.userData.favourites = {}; }
        if(this.userData.favourites[address] !== body) {
          this.userData.favourites[address] = body;
          Firebase.init().updateSingleFavourite(address, body, callback);
        } else {
          callback();
        }
      }
    },
    deleteFavourite(address, callback) {
      if(this?.userData?.favourites && this.userData.favourites[address]) {
        delete this.userData.favourites[address];
        Firebase.init().deleteFavourite(address, callback);
      } else {
        callback();
      }
    },
  },
  watch: {
    $route(to, from) {
      Utils.checkRouteForTransition(this, to, from);
    },
  },
  mounted() {
    //window.me = this;
    let readyFn = ()=>{
      if(document.readyState === 'complete') {
        this.getUserData();
        setTimeout(()=>{
          if(!Utils.dismissedBanner()) {
            this.banner = true;
          }
        }, 1000);
      } else {
        setTimeout(readyFn, 100);
      }
    };
    readyFn();
  },
}

</script>

<style lang="scss">
@import "~@/assets/scss/style.scss";
textarea { @include smallScrollbar; }
object[type="image/png"] {
  height: 50px;
}
.v-application__wrap {
  height: 100%;
}
.v-icon__svg {
  width: 100%;
}
</style>
