import AsyncScriptLoader from "@/assets/js/AsyncScriptLoader";
import Utils from "@/assets/js/Utils";

const Gtag = {
	init() {
		const MEASUREMENT_ID = "G-66EWW63MX9";
		if (Utils.acceptedCookies()) {
			AsyncScriptLoader.loadGtag(MEASUREMENT_ID, () => {
				if (!window.gtag) {
					window.dataLayer = window.dataLayer || [];
					window.gtag = function () {
						window.dataLayer.push(arguments);
					};
					window.gtag('js', new Date());
					window.gtag("config", MEASUREMENT_ID);
				}
			});
		}
	},
	sendAnalytic(action, category = "", label = "") {
		this.init();
		if (window.gtag) {
			window.gtag("event", action, {
				event_category: category,
				event_label: label,
			});
		} else if (Utils.acceptedCookies()) {
			setTimeout(()=>{
				this.sendAnalytic(action, category, label)
			}, 500);
		}
	},
};
export default Gtag;