<template>
  <div class="col-12">
    <div class="row pt-20 mb-40">
    <div class="col-12 centered">
      <p class="text-h4 text-align-center opacity-80">Vanity addresses</p>
      <p class="text-subtitle-2 mx-auto opacity-80">Find your personal address out of a list of thousands</p>
    </div>
  </div>
    <v-card class="glassed py-4 mb-10">
      <div class="row">
        <div ref="section-2" @click="clickSection(2)" class="col-12 pb-20 pointer no-select">
          <div class="row px-4">
            <div class="col-10 opacity-80" :class="openSection === 2 ? 'opacity-100' : ''">
              <p class="text-h4" :class="openSection === 2 ? 'opacity-100' : ''">Find your tailored address</p>
              <p class="text-subtitle-2">Explore a selection of notable and recognizable addresses</p>
            </div>
            <div class="col-2 justify-center align-end"><v-icon :color="openSection === 2 ? 'orange-darken-2' : ''" size="x-large">{{ icons[openSection === 2 ? 'up' : 'down'] }}</v-icon></div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="col-12">
          <transition name="gain-height">
            <div v-show="openSection === 2" class="row">
              <div class="col-12 centered px-4">
                <v-card class="back-blurred mt-0 mt-20 pb-0" style="width: 100%; min-width: 300px">
                  <v-text-field
                      ref="search-address-input"
                      density="compact"
                      label="Search pattern"
                      placeholder="John"
                      class="mb-0"
                      :error="!correctRegex"
                      v-model="currentSearch"
                      @update:modelValue="searchQuery"
                      hide-details="auto"
                      style="width: 100%;"
                  ></v-text-field>
                </v-card>
              </div>
              <div class="col-12">
                <div>
                  <v-container :class="isLoadingAddresses ? 'disabled' : ''" class="py-0">

                    <p v-show="!isLoadingAddresses && currentPagedAddrs.length > 0" class="text-align-center text-overline mt-10">
                      {{ currentPageOffset + 1 }} - {{ currentPageOffset + currentPagedAddrs.length }} of {{ parsedAddresses.length }} addresses found
                    </p>

                    <div class="col-12" style="padding: 0 20px">
                      <v-progress-linear
                          v-show="isLoadingAddresses"
                          class="opacity-60 mt-10"
                          height="2"
                          indeterminate
                      ></v-progress-linear>
                    </div>

                    <v-list v-show="currentPagedAddrs.length > 0" style="overflow: hidden">
                      <AddressListItem
                          v-for="(item, i) in currentPagedAddrs"
                          :key="i"
                          @click="closeAllAddressesDropdowns(i)"
                          :ref="'address-list-item-'+i"
                          :available="item.available"
                          :pattern="item.pattern"
                          :price="item.price"
                          :signature="item.message_signature"
                          :signed_message="item.signed_message"
                          :address="item.address"
                          :payed="parsedPayedCharges"
                          :is-usd="isUsd"
                          :curr-crypto="currCrypto"
                          @refresh-charges="getCharges"
                      >
                      </AddressListItem>
                    </v-list>

                    <v-list v-show="currentPagedAddrs.length === 0 && !!currentSearch">
                      <v-list-item v-if="!isLoadingAddresses">
                        <p v-if="correctRegex" class="text-h6 opacity-60">No addresses found matching <i>{{currentSearch}}</i> pattern.</p>
                        <p v-else class="text-body-1 opacity-60">
                          <span class="text-h6 text-orange">Invalid character found.</span>
                          <br>
                          Pattern must be <b>alphanumeric</b><br>
                          Pattern can not contain: <br>
                          &nbsp;&nbsp;&nbsp;&nbsp;<i class="text-subtitle-1">l</i> - <span class="text-caption"><small class="uppercase">lowercase </small>L</span>,<br>
                          &nbsp;&nbsp;&nbsp;&nbsp;<i class="text-subtitle-1">I</i> - <span class="text-caption"><small class="uppercase">uppercase </small>I</span>,<br>
                          &nbsp;&nbsp;&nbsp;&nbsp;<i class="text-subtitle-1">O</i> - <span class="text-caption"><small class="uppercase">uppercase </small>O</span>,<br>
                          &nbsp;&nbsp;&nbsp;&nbsp;<i class="text-subtitle-1">0</i> - <span class="text-caption"><small class="uppercase">zero</small></span>
                        </p>
                      </v-list-item>
                    </v-list>

                    <v-pagination
                        class="mt-3 text-orange truncate"
                        size="small"
                        theme="default"
                        v-model="currentPage"
                        v-show="currentPagedAddrs.length > 0"
                        :length="numberOfPages"
                        :total-visible="3"
                        :disabled="isLoadingAddresses"
                        @update:modelValue="fetchPagedAddrs"
                    ></v-pagination>

                  </v-container>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </v-card>
    <v-card v-if="checkedLogin && isLogged" class="glassed py-4 mb-10">
      <div class="row">
        <div ref="section-1" @click="clickSection(1)" class="col-12 pb-20 pointer no-select">
          <div class="row px-4">
            <div class="col-10 opacity-80" :class="openSection === 1 ? 'opacity-100' : ''">
              <p class="text-h4">Open charges</p>
            </div>
            <div class="col-2 justify-center align-end"><v-icon :color="openSection === 1 ? 'orange-darken-2' : ''" size="x-large">{{ icons[openSection === 1 ? 'up' : 'down'] }}</v-icon></div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="col-12">
          <transition name="gain-height">
            <div v-show="openSection === 1" class="row">
              <div class="col-12">
                <div>
                  <v-container :class="isLoadingCharges ? 'disabled' : ''">

                    <div class="col-12" style="padding: 0 20px">
                      <v-progress-linear
                          v-show="isLoadingCharges"
                          class="opacity-60"
                          height="2"
                          indeterminate
                      ></v-progress-linear>
                    </div>

                    <v-list v-if="parsedOpenCharges.length > 0" style="overflow: hidden">
                      <ChargeListItem
                          v-for="(item, i) in parsedOpenCharges"
                          :key="i"
                          :item="item"
                          :ref="'charge-list-item-'+i"
                          @click="closeAllChargesDropdowns(i)"
                          :payed="parsedPayedCharges"
                          :is-usd="isUsd"
                          :curr-crypto="currCrypto"
                      ></ChargeListItem>
                    </v-list>
                    <v-list v-else-if="parsedOpenCharges.length === 0">
                      <v-list-item v-if="!isLoadingCharges">
                        <p class="text-h6 opacity-60">No open charges found.</p>
                      </v-list-item>
                    </v-list>

                  </v-container>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </v-card>
    <v-card v-if="checkedLogin && isLogged" class="glassed py-4 mb-10">
      <div class="row">
        <div ref="section-0" @click="clickSection(0)" class="col-12 pb-20 pointer no-select">
          <div class="row px-4">
            <div class="col-10 opacity-80" :class="openSection === 0 ? 'opacity-100' : ''">
              <p class="text-h4">My addresses</p>
            </div>
            <div class="col-2 justify-center align-end"><v-icon :color="openSection === 0 ? 'orange-darken-2' : ''" size="x-large">{{ icons[openSection === 0 ? 'up' : 'down'] }}</v-icon></div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="col-12">
          <transition name="gain-height">
            <div v-show="openSection === 0" class="row">
              <div class="col-12">
                <div>
                  <v-container :class="isLoadingCharges ? 'disabled' : ''">

                    <div class="col-12" style="padding: 0 20px">
                      <v-progress-linear
                          v-show="isLoadingCharges"
                          class="opacity-60"
                          height="2"
                          indeterminate
                      ></v-progress-linear>
                    </div>

                    <v-list v-if="parsedPayedCharges.length > 0" style="overflow: hidden">
                      <PurchasedAddressListItem
                          v-for="(item, i) in parsedPayedCharges"
                          :key="i"
                          :ref="'purchase-list-item-'+i"
                          @click="closeAllPurchasesDropdowns(i)"
                          :item="item"
                      ></PurchasedAddressListItem>
                    </v-list>
                    <v-list v-else-if="parsedPayedCharges.length === 0">
                      <v-list-item v-if="!isLoadingCharges">
                        <p class="text-h6 opacity-60">No purchased addresses found.</p>
                      </v-list-item>
                    </v-list>

                  </v-container>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </v-card>
    <v-card v-if="checkedLogin && !isLogged" @click="clickShopLogBtn" class="no-select glassed py-4 mb-10">
      <div class="col-12 opacity-80">
        <p class="text-h4 mx-4">Login to view purchases</p>
        <v-divider></v-divider>
        <div class="row flex-end pt-20 pb-0">
          <v-btn color="transparent" v-text="'Login'" flat text-color="orange"></v-btn>
        </div>
      </div>
    </v-card>
    <div class="row">
      <div class="col-12 pt-100 align-end">
        <div>
          <div style="max-width: 344px; min-width: 250px;" class="glass orange tilt-card-shop no-select pointer mx-auto m-20" data-tilt>
            <div class="glass-content">
              <v-card
                  @click="$router.push('/verify')"
                  v-ripple
                  color="transparent"
              >
                <v-card-actions>
                  <v-btn color="transparent" text class="opacity-60 appear-up">Signature verifier</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pt-40 align-end">
        <div>
          <div style="max-width: 344px; min-width: 250px;" class="glass orange tilt-card-shop no-select pointer mx-auto m-20" data-tilt>
            <div class="glass-content">
              <v-card
                  @click="$router.push('/btc')"
                  v-ripple
                  color="transparent"
              >
                <v-card-actions>
                  <v-btn color="transparent" text class="opacity-60 appear-up">Go to explorer</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Utils from "@/assets/js/Utils";
import AddressListItem from "@/components/AddressListItem";
import PurchasedAddressListItem from "@/components/PurchasedAddressListItem";
import ChargeListItem from "@/components/ChargeListItem";
import Firebase from "@/assets/js/Firebase";
import Cryptos from "@/assets/js/Cryptos";
import API from "@/assets/js/API";
import { mdiChevronUp, mdiChevronDown, mdiArrowRight } from "@mdi/js";


export default {
  name: 'Shop',
  components: { AddressListItem, PurchasedAddressListItem, ChargeListItem },
  data() {
    return {
      icons: {
        up: mdiChevronUp,
        down: mdiChevronDown,
        arrowRight: mdiArrowRight,
      },
      currCrypto: Cryptos['btc'],
      minutesUpdatePrice: 5,
      currentPage: 1,
      itemsPerPage: 10,
      isLoadingAddresses: false,
      currentSearch: null,
      lastSearchQuery: null,
      timeoutSearch: null,
      addresses: {},
      isLoadingCharges: false,
      isLoadingChargesBackground: false,
      charges: {},
      openSection: 2,
      isUsd: true,
      checkChargesUpdates: null,
      illegalAddressChars: ["l", "I", "O", "0", " ", "'", ".", ","],
      metadata: {
        title: "Vanity addresses shop",
        description: "Find your personal bitcoin address, customized to fit your name. Instant payments powered with Lightning network!"
      },
    }
  },
  computed: {
    parsedAddresses() {
      let retutningArray = [];
      for (let addrId in this.addresses) {
        let newObj = Object.assign({}, this.addresses[addrId]);
        newObj.address = addrId;
        retutningArray.push(newObj);
      }
      return retutningArray;
    },
    parsedOpenCharges() {
      let retutningArray = [];
      for (let chargeId in this.charges) {
        let newObj = Object.assign({}, this.charges[chargeId]);
        newObj.chargeId = chargeId;
        if(!Firebase.init().pastTimestampMoreThan5MinsAgo(newObj.expires_at)) {
          retutningArray.push(newObj);
        }
      }
      return retutningArray;
    },
    parsedPayedCharges() {
      let retutningArray = [];
      for (let chargeId in this.charges) {
        let newObj = Object.assign({}, this.charges[chargeId]);
        newObj.chargeId = chargeId;
        if(newObj.status === 1) {
          retutningArray.push(newObj);
        }
      }
      return retutningArray;
    },
    numberOfPages() {
      let wholePagesCount = Math.floor(this.parsedAddresses.length / this.itemsPerPage);
      wholePagesCount += this.parsedAddresses.length % this.itemsPerPage ? 1 : 0;
      return wholePagesCount;
    },
    currentPageOffset() { return (this.currentPage - 1) * this.itemsPerPage; },
    currentPagedAddrs() {
      return this.parsedAddresses.slice(this.currentPageOffset, this.currentPage * this.itemsPerPage);
    },
    hasNonLoadedAddrs() { return this.parsedAddresses.length % 50 === 0 },
    lastLoadedId() {
      return this.parsedAddresses.length ? this.parsedAddresses[this.parsedAddresses.length - 1].address : null;
    },
    checkedLogin() { return !!window.app.checkedLogin; },
    isLogged() { return !!window.app.isLogged; },
    correctRegex() {
      if(!!this.currentSearch) {
        for(let i in this.illegalAddressChars) {
          let illegalChar = this.illegalAddressChars[i];
          if(this.currentSearch.includes(illegalChar)) { return false; }
        }
      }
      return true;
    },
  },
  methods: {
    clickSection(sectionNumber) {
      this.openSection = this.openSection === sectionNumber ? null : sectionNumber;
      if(this.openSection !== null) {
        Utils.scrollToElem(this.$refs['section-'+sectionNumber], 300);
      }
    },
    searchQuery(val) {
      if(val && this.correctRegex) { this.isLoadingAddresses = true; }
      clearTimeout(this.timeoutSearch);
      this.currentSearch = Utils.capitalizeFirstLetter((val ? val.toLowerCase() : ''));
      if(this.correctRegex && val) {
        this.timeoutSearch = setTimeout(()=>{
          this.searchAddresses(this.currentSearch);
        }, 500);
      } else {
        this.resetSearch();
        this.isLoadingAddresses = false;
      }
    },
    fetchPagedAddrs(page) {
      this.closeAllAddressesDropdowns();
      if(this.hasNonLoadedAddrs && page >= this.numberOfPages) {
        this.searchAddresses(this.currentSearch);
      }
    },
    resetSearch() {
      this.currentPage = 1;
      this.lastSearchQuery = '';
      this.addresses = {};
    },
    searchAddresses(query) {
      let eraseOldSearched = !!query && query !== this.lastSearchQuery;
      if(query) {
        Firebase.init().searchAddresses(query, r=>{
          if(eraseOldSearched) { this.resetSearch(); }
          this.lastSearchQuery = query;
          if(r) { this.addresses = Object.assign({}, this.addresses, r); }
          this.isLoadingAddresses = false;
        });
      } else {
        this.resetSearch();
        this.isLoadingAddresses = false;
      }
    },
    getCharges() {
      if(!this.isLoadingChargesBackground) {
        this.isLoadingChargesBackground = true;
        if(this.parsedOpenCharges.length === 0) { this.isLoadingCharges = true; }
        Firebase.init().getUserCharges(r=> {
          if (r && this.isUpdatedCharges(r)) {
            this.charges = Object.assign({}, r);
          }
          this.isLoadingCharges = false;
          this.isLoadingChargesBackground = false;
        });
      }
    },
    isUpdatedCharges(newCharges) {
      if(newCharges) {
        if(Object.keys(newCharges).length !== Object.keys(this.charges).length) {
          return true;
        }
        for(let chargeId in newCharges) {
          if(!this.charges[chargeId]) { return true; }
          if(this.charges[chargeId].status !== newCharges[chargeId].status) {
            return true;
          }
        }
      }
      return false;
    },
    randLetter() {
      const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ';
      return characters.charAt(Math.floor(Math.random() * characters.length));
    },
    closeAllAddressesDropdowns(except = null) {
      for(let i in this.currentPagedAddrs) {
        if(Number(i) !== except && this.$refs['address-list-item-'+i]) {
          if(this.$refs['address-list-item-'+i]?.[0]) {
            this.$refs['address-list-item-'+i][0].detailsShown = false;
          }
        }
      }
    },
    closeAllPurchasesDropdowns(except = null) {
      for(let i in this.parsedPayedCharges) {
        if(Number(i) !== except && this.$refs['purchase-list-item-'+i]) {
          this.$refs['purchase-list-item-'+i][0].detailsShown = false;
        }
      }
    },
    closeAllChargesDropdowns(except = null) {
      for(let i in this.parsedOpenCharges) {
        if(Number(i) !== except && this.$refs['charge-list-item-'+i]) {
          this.$refs['charge-list-item-'+i][0].detailsShown = false;
        }
      }
    },
    initLists() {
      if(this.checkedLogin && this.isLogged) {
        this.getCharges();
      } else if(!this.checkedLogin) {
        setTimeout(this.initLists, 100);
      }
    },
    fetchPrices() {
      let self = this;
      API.init().getUsdPrice(self.currCrypto.coinMarketCapId, (resp1) => {
        this.parseCoinMarketCapResponse(resp1, 'usd');
        setTimeout(() => {
          API.init().getEurPrice(self.currCrypto.coinMarketCapId, (resp) => {
            this.parseCoinMarketCapResponse(resp, 'eur');
            this.refreshLastPriceUpdate();
          });
        }, 2000);
      });
    },
    parseCoinMarketCapResponse(resp, currency) {
      if (resp?.body?.data !== undefined) {
        let newKey = Object.keys(resp.body.data)[0];
        let respPrice = resp.body.data[newKey].quote?.[currency.toUpperCase()]?.price;
        if (respPrice !== undefined) {
          this.currCrypto[currency + 'Price'] = respPrice;
          if (Utils.acceptedCookies()) {
            window.localStorage.setItem(this.currCrypto.coinMarketCapId.toString() + currency.toUpperCase() + "price", respPrice);
          }
        }
      }
    },
    refreshLastPriceUpdate() {
      let lastUpdate = new Date().setMinutes(
          new Date().getMinutes() + this.minutesUpdatePrice
      );
      if (Utils.acceptedCookies()) {
        window.localStorage.setItem(
            this.currCrypto.coinMarketCapId + "price_update",
            JSON.stringify(lastUpdate)
        );
      }
    },
    clickShopLogBtn() { window.app.login(); },
    initTimerCheckCharges() {
      this.checkChargesUpdates = setInterval(()=>{
        if(this.parsedOpenCharges.length && !this.isLoadingCharges) {
          this.getCharges();
        }
      }, 5000);
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth", });
    //this.searchAddresses(this.currentSearch ? this.currentSearch : this.randLetter());
    this.initLists();
    this.initTimerCheckCharges();
    this.$refs['search-address-input'].focus();

    let storageIsUsd = Utils.getStorageItem('isUsd');
    if (storageIsUsd) {
      this.isUsd = storageIsUsd === '1';
    }
    if (Utils.acceptedCookies()) {
      let currEur = window.localStorage.getItem(
          this.currCrypto.coinMarketCapId.toString() + "EURprice"
      );
      let currUsd = window.localStorage.getItem(
          this.currCrypto.coinMarketCapId.toString() + "USDprice"
      );
      if (currEur) {
        this.currCrypto.eurPrice = Number(currEur);
      }
      if (currUsd) {
        this.currCrypto.usdPrice = Number(currUsd);
      }
    }
    let lastUpdate = Utils.acceptedCookies() ? window.localStorage.getItem(this.currCrypto.coinMarketCapId + "price_update") : null;
    if (!lastUpdate || new Date(JSON.parse(lastUpdate)) < new Date()) {
      setTimeout(() => { this.fetchPrices(); }, 2000);
    }

    if(!!window.VanillaTilt) {
      window.VanillaTilt.init(document.querySelectorAll('.tilt-card-shop'));
    }
  },
  beforeUnmount() {
    window.clearInterval(this.checkChargesUpdates);
  },
  created() {
    document.title = this.metadata.title;
    document.querySelector('meta[name="description"]').setAttribute("content", this.metadata.description);
  },
};
</script>