<template>
  <div class="col-12 px-2">
    <v-list-item
        elevation="1"
        ref="list-item"
        @click="clickListItem"
        :active="detailsShown"
        style="backdrop-filter: blur(10px);"
        class="no-select"
        active-color="orange-darken-2">
      <v-list-item-header>
        <v-list-item-title class="word-wrap">{{ item.order_id }}</v-list-item-title>
        <v-list-item-subtitle class="word-wrap"><small>{{ item.address }}</small></v-list-item-subtitle>
      </v-list-item-header>
      <template v-slot:append>
        <div v-if="!detailsShown">
          <p><small>{{ priceUnit }}</small></p>
          <p style="line-height: 8px" class="text-overline"><small>{{ !isUsd ? eurUnitPrice : usdUnitPrice }}</small></p>
        </div>
      </template>
    </v-list-item>
    <div style="backdrop-filter: blur(10px)">
      <transition name="gain-height">
        <v-list-item border="1" class="no-select mb-5" v-show="detailsShown">
          <div class="row pt-2 pb-5">
            <div class="col-12">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Address</small></p>
              <div>
                <Tooltip text="Click to copy">
                  <p @click="clickToCopy" class="pointer monospace truncate">{{ item.address }}</p>
                </Tooltip>
              </div>
            </div>
            <div class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Charge date</small></p>
              <p class="truncate">{{ new Date(item.timestamp.seconds*1000).toLocaleString() }}</p>
            </div>
            <div v-if="!showPayed" class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Expires in</small></p>
              <p class="truncate" :class="liveTimer.length < 5 || liveTimer === 'Expired' ? 'text-red-accent-1' : ''">{{ liveTimer }}</p>
            </div>
            <div class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Total due</small></p>
              <p>{{ priceUnit }}</p>
              <p style="line-height: 8px" class="text-overline opacity-60"><small>{{ !isUsd ? eurUnitPrice : usdUnitPrice }}</small></p>
            </div>
            <div class="col-12 justify-center align-end pt-8">
              <v-btn v-if="!showPayed" :disabled="liveTimer === 'Expired'" @click="showPayReq = !showPayReq" min-width="200" color="transparent" flat text-color="orange-darken-2">Show payment request <v-icon class="ml-3" size="small">{{ icons.qr }}</v-icon></v-btn>
            </div>
            <transition name="gain-height">
              <div v-if="showPayReq && !showPayed" class="col-12 pt-3">
                <p style="line-height: 8px" class="opacity-40 text-overline mb-1"><small>Pay to</small></p>
                <Tooltip text="Click to copy" :class="liveTimer === 'Expired' ? 'disabled' : ''">
                  <p @click="clickToCopy" class="text-body-2 pointer monospace word-wrap" style="line-height: 14px"><small>{{ item.payreq }}</small></p>
                </Tooltip>
              </div>
              <div v-else-if="showPayed" class="col-12 pt-5 centered">
                <p class="text-h5 text-green-accent-3"><v-icon>{{icons.check}}</v-icon> PAYMENT COMPLETED</p>
              </div>
            </transition>
            <div v-show="loadingQr && !showPayed" class="col-12 centered pt-3">
              <v-progress-linear
                  class="opacity-60"
                  height="1"
                  indeterminate
              ></v-progress-linear>
            </div>
            <transition name="gain-height">
              <div v-if="showPayReq && !!qrImage && !showPayed" :class="liveTimer === 'Expired' ? 'disabled' : ''" class="col-12 centered pt-3">
                <div class="qr-container elevation-1" v-html="qrImage"></div>
              </div>
            </transition>
          </div>
        </v-list-item>
      </transition>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip";
import CryptoUtils from "@/assets/js/CryptoUtils";
import API from "@/assets/js/API";
import Utils from "@/assets/js/Utils";
import {mdiQrcodeScan, mdiCheck} from "@mdi/js";

export default {
  name: "ChargeListItem",
  components: { Tooltip },
  data() {
    return{
      icons: {
        qr: mdiQrcodeScan,
        check: mdiCheck,
      },
      detailsShown: false,
      currDate: new Date(),
      showPayReq: false,
      qrImage: false,
      loadingQr: false,
    }
  },
  props: {
    item: Object,
    payed: Object,
    currCrypto: Object,
    isUsd: Boolean,
  },
  methods: {
    clickToCopy(e) {
      navigator.clipboard.writeText(e.currentTarget.innerText);
      let tooltip = e.currentTarget.parentElement.querySelector('.tooltiptext');
      if (tooltip) {
        let target = e.currentTarget;
        target.classList.add('text-orange', 'no-pointer-events');
        let oldText = tooltip.innerText;
        tooltip.innerText = 'Copied!';
        setTimeout(() => {
          target.classList.remove('text-orange');
        }, 50);
        setTimeout(() => {
          tooltip.innerText = oldText;
          target.classList.remove('no-pointer-events');
        }, 1000);
      }
    },
    readableTimeLeft(date) {
      let diff = date.getTime() - this.currDate.getTime();
      if(diff <= 0) { return "Expired"; }
      let str = diff / 1000;
      let sec_num = parseInt(str, 10);
      let hours   = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours && hours   < 10) {hours   = "0"+hours;}
      if ((hours || minutes) && minutes < 10) {minutes = "0"+minutes;}
      if ((hours || minutes || seconds) && seconds < 10) {seconds = "0"+seconds;}
      return (hours ? hours + 'h : ' : '') + (minutes ? minutes + 'm : ' : '') + seconds+'s';
    },
    setCurrDate() {
      this.currDate = new Date();
    },
    clickListItem() {
      this.detailsShown = !this.detailsShown; this.showPayReq = false;
      if(this.detailsShown) {
        Utils.scrollToElem(this.$refs['list-item'].$el, 300);
      }
    },
  },
  computed: {
    priceUnit() { return CryptoUtils.numberToUnit(this.$props.item.amount, this.$props.currCrypto.unit); },
    eurUnitPrice() { return CryptoUtils.numberToEur(this.$props.item.amount, this.currCrypto.eurPrice, this.currCrypto.decimals); },
    usdUnitPrice() { return CryptoUtils.numberToUsd(this.$props.item.amount, this.currCrypto.usdPrice, this.currCrypto.decimals); },
    liveTimer() {
      return this.readableTimeLeft(new Date(this.$props.item.expires_at.seconds*1000));
    },
    showPayed() {
      return !!this.$props.payed.find(pc => pc.address === this.$props.item.address);
    },
  },
  mounted() {
    if(!this.qrImage) {
      this.loadingQr = true;
      API.init().getQr(this.$props.item.payreq, r=>{
        this.loadingQr = false;
        this.qrImage = !!r && !!r?.body ? r.body : null;
      });
    }
    setInterval(this.setCurrDate, 1000);
  }
}
</script>

<style lang="scss" scoped>

.v-list--density-default .v-list-item--prepend, .v-list--density-default .v-list-group--prepend > .v-list-group__header {
  -webkit-padding-start: 16px !important;
  padding-inline-start: 16px !important;
}

.qr-container {
  min-width: 300px;
  max-width: 500px;
  width: 40%;
  aspect-ratio: 1 / 1;
  border-radius: 5px;
  overflow: hidden;
}

</style>