import { createRouter, createWebHistory } from 'vue-router'
import ExplorerView from '../views/ExplorerView.vue'
import Home from '../views/Home.vue'
import Favourites from "@/views/Favourites";
import Shop from "@/views/Shop";
import Intro from "@/views/Intro";
import Verifier from "@/views/Verifier";

const routes = [
  {
    path: '/',
	alias: "",
    name: 'Explore the blockchain',
    component: Home
  },
	{
		path: "/btc",
		alias: "/btc/address/:address?",
		name: "Bitcoin Explorer",
		component: ExplorerView,
		props: (route) => ({
			crypto: "btc",
			address: route.params.address,
		}),
	},
	{
		path: "/sol",
		alias: "/sol/address/:address?",
		name: "Solana Explorer",
		component: ExplorerView,
		props: (route) => ({
			crypto: "sol",
			address: route.params.address,
		}),
	},
	{ path: "/shop", component: Shop , name: "Vanity addresses shop", },
	{ path: "/favourites", component: Favourites , name: "Favourites", },
	{ path: "/intro", component: Intro , name: "Introduction to Bitcoin", },
	{ path: "/verify", component: Verifier , name: "Bitcoin signature verifier", },
	{ path: "/:pathMatch(.*)*", component: Home , name: "Explore the blockchain", }
];
const scrollRouter = (to, from, savedPosition) => {
	/*const scrollTopSections = ['/', '/btc', '/sol', '/favourites', '/shop'];
	return scrollTopSections.includes(to.path) ? { top: 0 } : null;*/
}

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior: scrollRouter,
	mode: 'history',
	routes
})

export default router
