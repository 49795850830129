<template>
  <div class="col-12 pl-50 pr-50"><v-divider></v-divider></div>
  <div class="col-12">
    <v-system-bar>
      <div class="pointer" @click="$emit('backTxs', true)">
        <v-icon>{{ icons.arrowLeft }}</v-icon>
      </div>
      <v-spacer></v-spacer>
    </v-system-bar>
  </div>
  <div class="v-card-title text-body-1 col-12 mt-5">
    <div class="row">
      <div class="col-12 centered">
        <p class="opacity-60 text-h6 text-orange">Transaction details</p>
        <small class="word-wrap monospace">{{ parsedTx.id }}</small>
      </div>
    </div>
    <Tooltip class="col-shrink mx-auto" :text="parsedTx.confirmed ? 'Confirmed' : 'Pending'">
      <small class="opacity-60">Status: </small>
      <v-icon class="mb-1" size="small" :color="parsedTx.confirmed ? 'green-accent-3' : 'amber-darken-2'">
        {{ parsedTx.confirmed ? icons.check : icons.timerSand }}
      </v-icon>
    </Tooltip>
  </div>
  <div class="v-card-subtitle col-12 align-start opacity-100">
    <div class="row pt-5 pb-5">
      <div class="col-4 mobile-12 pb-5">
        <p class="text-overline opacity-60" style="max-height: 12px; line-height: 12px"><small>Timestamp</small></p>
        <p class="text-h6"><small>{{ parsedTx.confirmed ? blockDate : 'Just now' }}</small></p>
        <p v-if="parsedTx.confirmed"><span class="text-caption opacity-60">Included in block</span><span :title="parsedTx.block.hash" class="text-orange ml-2">#{{ parsedTx.block.number }}</span></p>
        <p v-else><span class="text-caption opacity-60">Unconfirmed</span></p>
      </div>
      <div class="col-4 centered mobile-12 pb-5">
        <div class="center-col">
          <p class="text-overline opacity-60" style="max-height: 12px; line-height: 12px"><small>Total moved</small></p>
          <p class="text-md-h5 text-h6">{{ mainFiat ? parsedTx.fiatTotal : parsedTx.cryptoTotal }}</p>
          <p class="text-caption opacity-60 text-uppercase">{{ mainFiat ? parsedTx.cryptoTotal : parsedTx.fiatTotal }}</p>
        </div>
      </div>
      <div class="col-4 mobile-12 pb-5">
        <p class="text-overline opacity-60" style="max-height: 12px; line-height: 12px"><small>Fee</small></p>
        <p class="text-h6"><small>{{ mainFiat ? parsedTx.fiatFee : parsedTx.cryptoFee }}</small> <span class="text-caption opacity-60">@{{ readableFeeRate }}sat/vByte</span></p>
        <p><span class="text-caption opacity-60">{{ mainFiat ? parsedTx.cryptoFee : parsedTx.fiatFee }}</span></p>
      </div>
    </div>
  </div>
  <div class="col-12 p-8">
    <div class="row mb-20">
      <div class="col-12 mb-2"><p class="text-align-center opacity-60 text-orange">Inputs</p></div>
      <div class="col-12">

        <div v-for="(vin, vinIndex) in parsedTx.ins" :key="vinIndex" class="row underlined-row">
          <div class="col-12">
            <span class="text-overline" style="line-height: 12px">
              <small class="opacity-60">#{{ vinIndex }}</small>
              <small v-if="!vin.isCoinbase && favourites[vin.address]" :class="'text-'+favourites[vin.address].color"><v-icon>{{ icons.star }}</v-icon>{{ favourites[vin.address].name }}</small>
            </span>
          </div>
          <div class="col-8 mobile-12 flex-start">
            <p class="monospace word-wrap" :class="!vin.isCoinbase && vin.address === address ? 'text-orange font-weight-bold' : ''">
              <small v-if="vin.isCoinbase">Coinbase</small>
              <small v-else-if="vin.address === address">{{ vin.address }}</small>
              <small v-else class="pointer" @click="changeAddress(vin.address)">{{ vin.address }}</small>
            </p>
            <p class="text-caption opacity-40"><small :class="vin.isCoinbase ? '' : 'uppercase'">{{ vin.isCoinbase ? 'Newly generated coins' : vin.addressType }}</small></p>
          </div>
          <div class="col-4 mobile-12 pl-20 flex-start align-end">
            <p class="text-subtitle-2 inputs-amount">{{ mainFiat ? vin.fiatVal : vin.cryptoVal }}</p>
            <small class="opacity-40">{{ mainFiat ? vin.cryptoVal : vin.fiatVal }}</small>
          </div>
        </div>

      </div>
    </div>
    <v-icon size="50" color="orange" class="opacity-60 mx-auto mb-20">{{ icons.down }}</v-icon>
    <div class="row">
      <div class="col-12 mb-2"><p class="text-align-center opacity-60 text-orange">Outputs</p></div>
      <div class="col-12">

        <div v-for="(vout, voutIndex) in parsedTx.outs" :key="voutIndex" class="row underlined-row">
          <div class="col-12">
            <span class="text-overline" style="line-height: 12px">
              <small class="opacity-60">#{{ voutIndex }}</small>
              <small v-if="favourites[vout.address]" :class="'text-'+favourites[vout.address].color"><v-icon>{{ icons.star }}</v-icon>{{ favourites[vout.address].name }}</small>
            </span>
          </div>
          <div class="col-8 mobile-12 flex-start">
            <p class="monospace word-wrap" :class="vout.address === address ? 'text-orange font-weight-bold' : ''">
              <small v-if="vout.address === address">{{ vout.address }}</small>
              <small v-else class="pointer" @click="changeAddress(vout.address)">{{ vout.address }}</small>
            </p>
            <p class="text-caption opacity-40"><small class="uppercase">{{ vout.addressType }}</small></p>
          </div>
          <div class="col-4 mobile-12 align-end pl-20 flex-start">
            <p class="text-subtitle-2 outputs-amount">{{ mainFiat ? vout.fiatVal : vout.cryptoVal }}</p>
            <small class="opacity-60">{{ mainFiat ? vout.cryptoVal : vout.fiatVal }}</small>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/Tooltip";
import CryptoUtils from "@/assets/js/CryptoUtils";
import { mdiStar, mdiArrowLeft, mdiCheck, mdiChevronDown, mdiTimerSandEmpty } from "@mdi/js";

export default {
  name: "TransactionDetail",
  components: { Tooltip },
  emits: ['backTxs'],
  props: {
    address: {type: String},
    currCrypto: {type: Object},
    isUsd: {type: Boolean},
    isConventional: {type: Boolean},
    mainFiat: {type: Boolean},
  },
  data() {
    return {
      icons: {
        star: mdiStar,
        arrowLeft: mdiArrowLeft,
        down: mdiChevronDown,
        check: mdiCheck,
        timerSand: mdiTimerSandEmpty,

      },
      txDetails: { id: '', ins: [], outs: [], totalMoved: 0, fee: 0, feeRate: 0, block: null, date: null, confirmed: false },
    }
  },
  computed: {
    blockDate() { return !!this.txDetails.date && !isNaN(this.txDetails.date.getTime()) ? this.txDetails.date.toLocaleDateString()+' '+this.txDetails.date.toLocaleTimeString() : ''; },
    parsedTx() {
      let cr = this.currCrypto;
      let txD = this.txDetails;

      txD.totalEur = CryptoUtils.numberToEur(txD.totalMoved, cr.eurPrice, cr.decimals);
      txD.totalUsd = CryptoUtils.numberToUsd(txD.totalMoved, cr.usdPrice, cr.decimals);
      txD.totalUnit = CryptoUtils.numberToUnit(txD.totalMoved, cr.unit);
      txD.totalConventional = CryptoUtils.numberToConventional(txD.totalMoved, cr.symbol, cr.decimals);
      txD.totalConventional = CryptoUtils.numberToConventional(txD.totalMoved, cr.symbol, cr.decimals);
      txD.fiatTotal = this.isUsd ? txD.totalUsd : txD.totalEur;
      txD.cryptoTotal = this.isConventional ? txD.totalConventional : txD.totalUnit;

      txD.feeEur = CryptoUtils.numberToEur(txD.fee, cr.eurPrice, cr.decimals);
      txD.feeUsd = CryptoUtils.numberToUsd(txD.fee, cr.usdPrice, cr.decimals);
      txD.feeUnit = CryptoUtils.numberToUnit(txD.fee, cr.unit);
      txD.feeConventional = CryptoUtils.numberToConventional(txD.fee, cr.symbol, cr.decimals);
      txD.feeConventional = CryptoUtils.numberToConventional(txD.fee, cr.symbol, cr.decimals);
      txD.fiatFee = this.isUsd ? txD.feeUsd : txD.feeEur;
      txD.cryptoFee = this.isConventional ? txD.feeConventional : txD.feeUnit;

      txD.ins.forEach(vin => {
        vin.eurVal = CryptoUtils.numberToEur(vin.value, cr.eurPrice, cr.decimals);
        vin.usdVal = CryptoUtils.numberToUsd(vin.value, cr.usdPrice, cr.decimals);
        vin.unitVal = CryptoUtils.numberToUnit(vin.value, cr.unit);
        vin.conventionalVal = CryptoUtils.numberToConventional(vin.value, cr.symbol, cr.decimals);
        vin.fiatVal = this.isUsd ? vin.usdVal : vin.eurVal;
        vin.cryptoVal = this.isConventional ? vin.conventionalVal : vin.unitVal;
      });

      txD.outs.forEach(vout => {
        vout.eurVal = CryptoUtils.numberToEur(vout.value, cr.eurPrice, cr.decimals);
        vout.usdVal = CryptoUtils.numberToUsd(vout.value, cr.usdPrice, cr.decimals);
        vout.unitVal = CryptoUtils.numberToUnit(vout.value, cr.unit);
        vout.conventionalVal = CryptoUtils.numberToConventional(vout.value, cr.symbol, cr.decimals);
        vout.fiatVal = this.isUsd ? vout.usdVal : vout.eurVal;
        vout.cryptoVal = this.isConventional ? vout.conventionalVal : vout.unitVal;
      });
      return this.txDetails;
    },
    readableFeeRate() { return CryptoUtils.readableNumber(this.parsedTx.feeRate, 2); },
    favourites() { return window.app?.userData?.favourites ? window.app.userData.favourites : {}; },
  },
  methods: {
    changeAddress(address) {
      this.$router.push('/'+this.currCrypto.symbol.toLowerCase()+'/address/'+address)
    }
  },
  //mounted() { window.me = this; }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
  @media all and (max-width: 768px){
    .center-col {
      margin-right: auto;
    }
  }
  .underlined-row {
    &:nth-child(2n) { background-color: rgba(#9e9e9e, .05); }
    position: relative;
    cursor: default;
    padding: 7px 16px;
    &::after {
      @include transition(width);
      @include pseudoLine(1px, 0, calc(100% - 1px), 50%, #9e9e9e);
      transform: translateX(-50%);
      opacity: .3;
    }
    &:hover::after {
      width: 80%;
    }
  }
</style>