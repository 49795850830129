<template>
  <v-footer class="bg-transparent back-blurred" app>
    <v-card
        elevation="0"
        rounded="0"
        width="100%"
        class="text-center bg-transparent"
    >
      <v-divider></v-divider>
      <v-card-text class="opacity-100">
        <div class="row space-evenly">
          <div class="col-4 mobile-12 p-15 align-start">
            <div class="row centered">
              <div class="col-12 align-start">
                <p class="text-overline opacity-60">Credits</p>
                <a class="show-text-hover text-decoration-none" href="https://aws.amazon.com/it/lambda/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/lambda-logo.svg`)" height="20" width="20" alt="Logo AWS Lambda" /> AWS Lambda</p></a>
                <a class="show-text-hover text-decoration-none" href="https://firebase.google.com/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/opennode-logo.svg`)" height="20" width="20" alt="Logo Open Node" /> Open Node</p></a>
                <a class="show-text-hover text-decoration-none" href="https://firebase.google.com/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/firebase.png`)" height="20" width="14.5" alt="Logo Google Firebase" /> Google Firebase</p></a>
                <a class="show-text-hover text-decoration-none" href="https://mempool.space/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/mempool.png`)" height="20" width="20" alt="Logo Mempool.space" /> Mempool.space</p></a>
                <a class="show-text-hover text-decoration-none" href="https://solanabeach.io/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/solana-beach.svg`)" height="20" width="21" alt="Logo Solana Beach" /> Solana Beach</p></a>
                <a class="show-text-hover text-decoration-none" href="https://coinmarketcap.com/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/coinmarketcap.png`)" height="20" width="20" alt="Logo CoinMarketCap" /> CoinMarketCap</p></a>
                <a class="show-text-hover text-decoration-none" href="https://netlify.com/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/netlify.svg`)" height="20" width="20" alt="Logo Netlify" /> Netlify</p></a>
                <a class="show-text-hover text-decoration-none" href="https://next.vuetifyjs.com/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/logo.svg`)" height="20" width="17.5" alt="Logo Vuetify" /> Vuetify 3 Alpha</p></a>
                <a class="show-text-hover text-decoration-none" href="https://v3.vuejs.org/" target="_blank"><p class="text-caption text-grey"><img :src="require(`@/assets/img/logo.png`)" height="20" width="20" alt="Logo Vue" /> Vue v3</p></a>
              </div>
            </div>
          </div>
          <div class="col-4 mobile-12 centered order-mobile-3">
<!--            <p><a class="text-caption text-grey" href="https://bitcoin.org" target="_blank">bitcoin.org</a></p>-->
            <p><a class="text-caption text-grey" href="mailto:gurga@mezcla.app">Email me</a></p>
          </div>
          <div class="col-4 mobile-12 centered">
            <div class="row centered flex-end">
              <div class="col-shrink mobile-12  centered opacity-60 no-pointer-events no-select">
                <img width="50" height="50" :src="require(`@/assets/img/btc-3d.svg`)" alt="BTC 3d graphic">
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text>
        {{ new Date().getFullYear() }}
        <v-btn flat size="small" text-color="orange" color="transparent" href="https://github.com/g-flex" target="_blank">g-flex</v-btn>©
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
  a.show-text-hover {
    height: 30px;
    p {
      transition: font-size .1s linear;
      font-size: 9px !important;
    }
    &:hover p {
      font-size: 14px !important;
    }
    a:visited,a:active{ color: default; }
  }
</style>