<template>
  <div class="col-12 px-2">
    <v-list-item
        elevation="1"
        ref="list-item"
        @click="clickListItem"
        :active="detailsShown"
        style="backdrop-filter: blur(10px)"
        class="no-select"
        :color="!available && !showPayed ? 'red-lighten-2' : !available && showPayed ? 'green-accent-3' : ''"
        :class="!available ? 'no-pointer-events opacity-60' : ''"
        :prepend-icon="icons['numeric'+pattern.length+'Box']"
        active-color="orange-darken-2">
      <v-list-item-header>
        <v-list-item-title class="word-wrap">{{ pattern }}</v-list-item-title>
        <v-list-item-subtitle class="word-wrap"><small>{{ !!available ? address : address.slice(0, 12)+'...' }}</small></v-list-item-subtitle>
      </v-list-item-header>
      <template v-slot:append>
        <div v-if="!detailsShown && !!available">
          <p><small>{{ priceUnit }}</small></p>
          <p style="line-height: 8px" class="text-overline"><small>{{ !isUsd ? eurUnitPrice : usdUnitPrice }}</small></p>
        </div>
        <div v-else-if="!available">
          <p>{{ showPayed ? 'ACQUIRED' : 'SOLD' }}</p>
        </div>
      </template>
    </v-list-item>
    <div style="backdrop-filter: blur(10px)">
      <transition name="gain-height">
        <v-list-item border="1" class="no-select mb-5" v-show="detailsShown">
          <div class="row pt-2 pb-5">
            <div class="col-12">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Address</small></p>
              <div>
                <Tooltip text="Click to copy">
                  <p @click="clickToCopy" class="pointer monospace truncate">{{ address }}</p>
                </Tooltip>
              </div>
            </div>
            <div class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Pattern</small></p>
              <p class="truncate">{{ pattern }}</p>
            </div>
            <div class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Pattern length</small></p>
              <p class="truncate">{{ pattern.length }}</p>
            </div>
            <div class="col-12 pt-3 align-start">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Proof of ownership</small></p>
              <v-btn :text-color="signatureShown ? 'orange-darken-2' : ''" class="mt-1" size="small" flat @click="signatureShown = !signatureShown">{{ (signatureShown ? 'Hide' : 'View') + ' signature' }}</v-btn>
              <transition name="gain-height">
                <div class="row pt-2" v-show="signatureShown">
                  <div class="col-6 mobile-12 pt-3">
                    <p style="line-height: 8px" class="opacity-40 text-overline"><small>Signed message</small></p>
                    <div>
                      <Tooltip text="Click to copy">
                        <p @click="clickToCopy" class="pointer text-caption word-wrap opacity-80">{{ signed_message }}</p>
                      </Tooltip>
                    </div>
                  </div>
                  <div class="col-6 mobile-12 pt-3">
                    <p style="line-height: 8px" class="opacity-40 text-overline"><small>Signature</small></p>
                    <div>
                      <Tooltip text="Click to copy">
                        <p @click="clickToCopy" class="pointer text-caption word-wrap opacity-80">{{ signature }}</p>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="col-12 align-end">
              <p><small>{{ priceUnit }}</small></p>
              <p style="line-height: 8px" class="text-overline opacity-60"><small>{{ !isUsd ? eurUnitPrice : usdUnitPrice }}</small></p>
            </div>
            <div class="col-12 centered pt-8">
              <div>
                <v-btn v-if="hasUid" :disabled="showingPayReq" @click="showPaymentRequest" min-width="200" color="orange">Buy now <v-icon>{{icons.lightning}}</v-icon></v-btn>
                <v-btn v-else :disabled="showingPayReq" @click="clickShopLogBtn" min-width="200" color="orange">Login to buy now <v-icon>{{icons.lightning}}</v-icon></v-btn>
              </div>
              <v-progress-linear
                  v-show="isLoadingPayReq"
                  class="opacity-60 mt-8"
                  height="1"
                  indeterminate
              ></v-progress-linear>
            </div>
            <transition name="gain-height">
              <div v-if="showingPayReq && retrievedPayReq" class="col-12 pt-3">
                <div class="row">
                  <div v-if="!showPayed" class="col-12">
                    <p style="line-height: 8px" class="opacity-40 text-overline mb-1"><small>Pay to</small></p>
                    <Tooltip text="Click to copy">
                      <p @click="clickToCopy" class="text-body-2 pointer monospace word-wrap" style="line-height: 14px"><small>{{ retrievedPayReq }}</small></p>
                    </Tooltip>
                  </div>
                  <div v-if="showPayed" class="col-12 centered pt-5">
                    <p class="text-h5 text-green-accent-3"><v-icon>{{ icons.check }}</v-icon> PAYMENT COMPLETED</p>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="gain-height">
              <div v-if="showingPayReq && retrievedPayReq && !!qrImage && !showPayed" class="col-12 centered pt-3">
                <div class="qr-container elevation-1" v-html="qrImage"></div>
              </div>
            </transition>
          </div>
        </v-list-item>
      </transition>
    </div>
  </div>
</template>

<script>
import API from "@/assets/js/API";
import Firebase from "@/assets/js/Firebase";
import Tooltip from "@/components/Tooltip";
import CryptoUtils from "@/assets/js/CryptoUtils";
import Utils from "@/assets/js/Utils";
import {mdiArrowRight, mdiLightningBolt, mdiCheck, mdiNumeric0Box, mdiNumeric1Box, mdiNumeric2Box, mdiNumeric3Box, mdiNumeric4Box, mdiNumeric5Box, mdiNumeric6Box, mdiNumeric7Box, mdiNumeric8Box, mdiNumeric9Box} from "@mdi/js";

export default {
  name: "AddressListItem",
  components: { Tooltip },
  props: {
    pattern: String,
    address: String,
    signed_message: String,
    signature: String,
    available: Boolean,
    price: Number,
    payed: Object,
    currCrypto: Object,
    isUsd: Boolean,
  },
  data() {
    return{
      icons: {
        arrowRight: mdiArrowRight,
        lightning: mdiLightningBolt,
        check: mdiCheck,
        numeric0Box: mdiNumeric0Box,
        numeric1Box: mdiNumeric1Box,
        numeric2Box: mdiNumeric2Box,
        numeric3Box: mdiNumeric3Box,
        numeric4Box: mdiNumeric4Box,
        numeric5Box: mdiNumeric5Box,
        numeric6Box: mdiNumeric6Box,
        numeric7Box: mdiNumeric7Box,
        numeric8Box: mdiNumeric8Box,
        numeric9Box: mdiNumeric9Box,
      },
      detailsShown: false,
      signatureShown: false,
      retrievedPayReq: null,
      showingPayReq: false,
      isLoadingPayReq: false,
      qrImage: null,
    }
  },
  computed: {
    hasUid() { return !!Firebase.init().uid; },
    priceUnit() { return CryptoUtils.numberToUnit(this.$props.price, this.$props.currCrypto.unit); },
    eurUnitPrice() { return CryptoUtils.numberToEur(this.$props.price, this.currCrypto.eurPrice, this.currCrypto.decimals); },
    usdUnitPrice() { return CryptoUtils.numberToUsd(this.$props.price, this.currCrypto.usdPrice, this.currCrypto.decimals); },
    showPayed() {
      return !!this.$props.payed.find(pc => pc.address === this.$props.address);
    },
  },
  methods: {
    clickToCopy(e) {
      navigator.clipboard.writeText(e.currentTarget.innerText);
      let tooltip = e.currentTarget.parentElement.querySelector('.tooltiptext');
      if (tooltip) {
        let target = e.currentTarget;
        target.classList.add('text-orange', 'no-pointer-events');
        let oldText = tooltip.innerText;
        tooltip.innerText = 'Copied!';
        setTimeout(() => {
          target.classList.remove('text-orange');
        }, 50);
        setTimeout(() => {
          tooltip.innerText = oldText;
          target.classList.remove('no-pointer-events');
        }, 1000);
      }
    },
    showPaymentRequest() {
      if(!this.showingPayReq) {
        if(!this.retrievedPayReq) {
          this.isLoadingPayReq = true;
          API.init().getCharge(this.$props.address, Firebase.init().uid, r=> {
            this.showingPayReq = true;
            if(!!r?.body && typeof r.body === "object") {
              for(let i in r.body) {
                if(!!r.body[i]?.payreq) {
                  this.retrievedPayReq = r.body[i].payreq;
                  break;
                }
              }
              API.init().getQr(this.retrievedPayReq, r=>{
                this.isLoadingPayReq = false;
                this.qrImage = !!r && !!r?.body ? r.body : null;
              });
              this.$emit('refreshCharges');
            } else {
              this.isLoadingPayReq = false;
            }
          });
        } else {
          this.showingPayReq = true;
        }
      } else {
        this.showingPayReq = false;
      }
    },
    clickShopLogBtn() { window.app.login(); },
    clickListItem() {
      this.detailsShown = !!this.available ? !this.detailsShown : false;
      if(this.detailsShown) {
        Utils.scrollToElem(this.$refs['list-item'].$el, 300);
      }
    },
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>

  .v-list--density-default .v-list-item--prepend, .v-list--density-default .v-list-group--prepend > .v-list-group__header {
    -webkit-padding-start: 16px !important;
    padding-inline-start: 16px !important;
  }

  .qr-container {
    min-width: 300px;
    max-width: 500px;
    width: 40%;
    aspect-ratio: 1 / 1;
    border-radius: 5px;
    overflow: hidden;
  }

</style>