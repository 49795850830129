const CryptoUtils = {
	readableNumber(number = 0, decimals = 0, auto = true) {
		let options = auto
			? {
				minimumFractionDigits: 0,
				maximumFractionDigits: decimals,
			}
			: {
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
			};
		return number.toLocaleString(undefined, options);
	},
	numberToFiat(amount, symbol, price, decimals) {
		let returningAmount = "0";
		if (amount) {
			let finalVal = (Number(amount) / 10 ** decimals) * price;
			returningAmount = this.readableNumber(finalVal, 2, false);
		}
		return `${symbol} ${returningAmount}`;
	},
	switchUnit(amount, symbol, decimals) {
		let returningAmount = "0";
		if (amount) {
			if (decimals) {
				returningAmount = this.readableNumber(Number(amount) / 10 ** decimals, decimals);
			} else {
				returningAmount = this.readableNumber(Number(amount), decimals);
			}
		}
		return `${returningAmount} ${symbol}`;
	},

	numberToUnit(amount, symbol) {
		return this.switchUnit(amount, symbol, 0);
	},
	numberToConventional(amount, symbol, decimals) {
		return this.switchUnit(amount, symbol, decimals);
	},
	numberToEur(amount, price, decimals) {
		return this.numberToFiat(amount, '€', price, decimals);
	},
	numberToUsd(amount, price, decimals) {
		return this.numberToFiat(amount, '$', price, decimals);
	},

	eurConventionalPrice(price) {
		return '€ '+this.readableNumber(price);
	},
	usdConventionalPrice(price) {
		return '$ '+this.readableNumber(price);
	},
	eurNonConventionalPrice(price, decimals) {
		let eurPrice = price / 10 ** decimals;
		return '€ ' + this.readableNumber(eurPrice, 9);
	},
	usdNonConventionalPrice(price, decimals) {
		let usdPrice = price / 10 ** decimals;
		return '$ ' + this.readableNumber(usdPrice, 9);
	},
};

export default CryptoUtils;
