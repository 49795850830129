<template>
  <div class="col-12">
    <div class="row pb-20">
      <div class="col-3"></div>
      <div class="col-6 medium-12 order-medium-2">
        <div class="col-12 centered">
          <p class="text-h4">{{$route.name}}</p>
          <p class="text-subtitle-2 text-align-center mx-auto opacity-80">Imagine if you could view any movement of any bank account without asking the bank</p>
        </div>
      </div>
      <div class="col-3 medium-12 medium-12 mb-2 order-medium-1">
        <div ref="settings-buttons" class="row flex-end pt-20">
          <div class="mr-20">
            <v-btn
                @click="switchFiat"
                rounded="pill"
                color="orange"
                class="glassed"
                variant="outlined"
            >{{ isConventional ? currCrypto.symbol : currCrypto.unit }}
            </v-btn>
          </div>
          <div>
            <v-btn
                @click="switchUnit"
                rounded="pill"
                color="orange"
                class="glassed"
                variant="outlined"
            >{{ isUsd ? 'USD' : 'EUR' }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="row centered mb-30">
      <v-list density="compact">
        <v-list-item
            v-for="(listItem, i) in subtitleList"
            :key="i"
            :value="listItem"
        >
          <v-list-item-avatar left>
            <v-icon :icon="listItem.icon"></v-icon>
          </v-list-item-avatar>
          <v-list-item-subtitle v-text="listItem.text"></v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </div>
    <Form
        @clear-error="clearError"
        @open-form="openForm"
        :active-form="isActiveForm"
        :error-form="isErrorForm"
        :should-load="shouldLoadForm"
        :prefilled-address="address"
        class="mb-20"
        @submit="parseInputAddress"
        @delete-address-from-history="deleteAddressFromHistory"
    />
    <transition name="gain-height">
      <ResultCard
          class="mb-20"
          ref="card"
          v-show="!!foundAddress.address"
          @fetch-txs="clickedFetchTransactions"
          @fetch-paged-txs="fetchPagedTxs"
          @fetch-detail-tx="fetchDetailTx"
          :curr-crypto="currCrypto"
          :is-usd="isUsd"
          :is-conventional="isConventional"
          :address="foundAddress.address"
          :total-transacted="totalTransacted"
          :balance="foundAddress.balance"
          :price="priceCaption"
          :fiat-balance="isUsd ? finalBalanceUsd : finalBalanceEur"
          :crypto-balance="isConventional ? finalBalanceConventional : finalBalanceUnit"
          :confirmed-txs="foundAddress.totalTxs"
          :unconfirmed-txs="foundAddress.unconfirmedTotalTxs"
      />
    </transition>
    <div class="row">
      <div class="col-12 pt-100 align-end">
        <div>
          <div style="max-width: 344px; min-width: 250px;" class="glass orange tilt-card-explorer no-select pointer mx-auto m-20" data-tilt>
            <div class="glass-content">
              <v-card
                  @click="$router.push('/verify')"
                  v-ripple
                  color="transparent"
              >
                <v-card-actions>
                  <v-btn color="transparent" text class="opacity-60 appear-up">Signature verifier</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pt-40 align-end">
        <div>
          <div style="max-width: 344px; min-width: 250px;" class="glass purple tilt-card-explorer no-select pointer mx-auto m-20" data-tilt>
            <div class="glass-content">
              <v-card
                  @click="$router.push('/shop')"
                  v-ripple
                  color="transparent"
              >
                <v-card-actions>
                  <v-btn color="transparent" text class="opacity-60 appear-up">Go to Shop</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index";
import Form from '@/components/Form'
import ResultCard from '@/components/ResultCard'
import Utils from "@/assets/js/Utils";
import API from "@/assets/js/API";
import Cryptos from "@/assets/js/Cryptos";
import CryptoUtils from "@/assets/js/CryptoUtils";
import Firebase from "@/assets/js/Firebase";
import Gtag from "@/assets/js/Gtag";
import {mdiArrowRight,mdiStar,mdiMagnify,mdiWallet,mdiReceipt} from "@mdi/js";

export default {
  name: "ExplorerView",
  components: {Form, ResultCard},
  props: {
    crypto: {type: String, default: 'btc'},
    address: { default: '' }
  },
  data() {
    return {
      icons: {
        arrowRight: mdiArrowRight,
        star: mdiStar,
        search: mdiMagnify,
        wallet: mdiWallet,
        receipt: mdiReceipt,
      },
      currCrypto: Cryptos[this.crypto] ? Cryptos[this.crypto] : Cryptos['btc'],
      timeoutFetchTxs: null,
      activeForm: true,
      errorForm: false,
      isConventional: true,
      isUsd: true,
      minutesUpdatePrice: 5,
      shouldLoadForm: false,
      foundAddress: {
        address: "",
        balance: 0,
        totalTxs: 0,
        unconfirmedBalance: 0,
        unconfirmedTotalTxs: 0
      },
      foundTxs: [],
      subtitleList: [
        { text: 'Find any address on the blockchain', icon: mdiMagnify },
        { text: 'View balance', icon: mdiWallet },
        { text: 'View transactions', icon: mdiReceipt },
        { text: 'Login and add addresses to favourites', icon: mdiStar },
      ],
      metadata: {
        title: this.$route.name,
        description: "Track any bitcoin address, view income and spent money, list all transactions. Sign in to access favourites and other cool features!",
      },
    }
  },
  computed: {
    isErrorForm() { return this.errorForm; },
    isActiveForm() { return this.activeForm; },
    finalBalanceUnit() {
      return CryptoUtils.numberToUnit(this.foundAddress.balance + this.foundAddress.unconfirmedBalance, this.currCrypto.unit);
    },
    finalBalanceConventional() {
      return CryptoUtils.numberToConventional(this.foundAddress.balance + this.foundAddress.unconfirmedBalance, this.currCrypto.symbol, this.currCrypto.decimals);
    },
    finalBalanceEur() {
      return CryptoUtils.numberToEur(this.foundAddress.balance + this.foundAddress.unconfirmedBalance, this.currCrypto.eurPrice, this.currCrypto.decimals);
    },
    finalBalanceUsd() {
      return CryptoUtils.numberToUsd(this.foundAddress.balance + this.foundAddress.unconfirmedBalance, this.currCrypto.usdPrice, this.currCrypto.decimals);
    },
    totalTransacted() {
      let fa = this.foundAddress;
      return fa.totalSpent !== undefined && fa.totalReceived !== undefined ? {
        spentCrypto: this.isConventional ? CryptoUtils.numberToConventional(fa.totalSpent, this.currCrypto.symbol, this.currCrypto.decimals) : CryptoUtils.numberToUnit(fa.totalSpent, this.currCrypto.unit),
        receivedCrypto: this.isConventional ? CryptoUtils.numberToConventional(fa.totalReceived, this.currCrypto.symbol, this.currCrypto.decimals) : CryptoUtils.numberToUnit(fa.totalSpent, this.currCrypto.unit),
        spentFiat: this.isUsd ? CryptoUtils.numberToUsd(fa.totalSpent, this.currCrypto.usdPrice, this.currCrypto.decimals) : CryptoUtils.numberToEur(fa.totalSpent, this.currCrypto.eurPrice, this.currCrypto.decimals),
        receivedFiat: this.isUsd ? CryptoUtils.numberToUsd(fa.totalReceived, this.currCrypto.usdPrice, this.currCrypto.decimals) : CryptoUtils.numberToEur(fa.totalReceived, this.currCrypto.eurPrice, this.currCrypto.decimals),
      } : null;
    },
    unconfirmedBalanceUnit() {
      return CryptoUtils.numberToUnit(this.foundAddress.unconfirmedBalance, this.currCrypto.unit);
    },
    unconfirmedBalanceConventional() {
      return CryptoUtils.numberToConventional(this.foundAddress.unconfirmedBalance, this.currCrypto.symbol, this.currCrypto.decimals);
    },
    unconfirmedBalanceEur() {
      return CryptoUtils.numberToEur(this.foundAddress.unconfirmedBalance, this.currCrypto.eurPrice, this.currCrypto.decimals);
    },
    unconfirmedBalanceUsd() {
      return CryptoUtils.numberToUsd(this.foundAddress.unconfirmedBalance, this.currCrypto.usdPrice, this.currCrypto.decimals);
    },
    eurPrice() { return CryptoUtils.eurConventionalPrice(this.currCrypto.eurPrice); },
    usdPrice() { return CryptoUtils.usdConventionalPrice(this.currCrypto.usdPrice); },
    eurNonCPrice() { return CryptoUtils.eurNonConventionalPrice(this.currCrypto.eurPrice, this.currCrypto.decimals); },
    usdNonCPrice() { return CryptoUtils.usdNonConventionalPrice(this.currCrypto.usdPrice, this.currCrypto.decimals); },
    priceCaption() {
      let str = '1 ' + (this.isConventional ? this.currCrypto.symbol : this.currCrypto.unit) + ' ≈ ';
      let readableNum = '';
      if(this.isConventional) {
        readableNum = this.isUsd ? this.usdPrice : this.eurPrice;
      } else {
        readableNum = this.isUsd ? this.usdNonCPrice : this.eurNonCPrice;
      }
      return str += readableNum;
    },

  },
  methods: {
    parseInputAddress(value) {
      window.clearTimeout(this.timeoutFetchTxs);
      this.shouldLoadForm = true;
      this.activeForm = true;
      this.$refs.card.resetTxData();
      this.$refs.card.isLoading = true;
      API.init()[this.currCrypto.fn](value, (resp)=>{
        this.shouldLoadForm = false;
        this.$refs.card.isLoading = false;
        this.foundAddress = this.currCrypto.parseRespData(resp);
        this.errorForm = !this.currCrypto.validResp(resp);
        this.activeForm = this.errorForm;
        if(!this.errorForm) {
          this.$emit('addAddressHistory', {address: value, crypto: this.$route.path.substr(1,3)});
          Gtag.sendAnalytic("Searched Address", this.currCrypto.symbol, this.foundAddress.address);
          Utils.scrollToElem(this.$refs.card.$el, 150);
          router.push({
            path: "/" + this.currCrypto.symbol.toLowerCase() + "/address/" + this.foundAddress.address,
          });
          this.timeoutFetchTxs = setTimeout(()=>{
            if(!this.activeForm) {
              this.fetchTransactions();
            }
          }, 500);
        } else {
          router.push({
            path: "/" + this.currCrypto.symbol.toLowerCase(),
          });
        }
      });
    },
    fetchDetailTx(tx){
      Gtag.sendAnalytic("Transaction details", this.currCrypto.symbol, tx);
      this.$refs.card.$refs.table.isPaginationLoading = true;
      API.init()[this.currCrypto.txDetailFn](tx.txId, (resp) => {
        let fetchedTxDetails = this.currCrypto.parseTxDetailData(resp);
        if(fetchedTxDetails) {
          this.$refs.card.$refs.table.showTxBreakdown = true;
          setTimeout(()=>{
            this.$refs.card.$refs.table.$refs.detail.txDetails = fetchedTxDetails;
            this.$refs.card.$refs.table.isPaginationLoading = false;
            Utils.scrollToElem(this.$refs.card.$refs.table.$el, 150);
          }, 300);
        } else {
          console.error('get btc txId '+tx.txId+' returned invalid response');
          this.$refs.card.$refs.table.isPaginationLoading = false;
        }
      });
    },
    fetchPagedTxs() {
      this.$refs.card.$refs.table.isPaginationLoading = true;
      let getTxsObj = this.currCrypto.fetchPagedTxs(this.foundAddress.address, this.foundTxs.length, this.foundTxs[this.foundTxs.length-1].txId);
      API.init()[getTxsObj.apiFn](this.foundAddress.address, getTxsObj.data, (resp) => {
        let parsedNewTxs = this.currCrypto.parseAddressTxsRespData(this.foundAddress.address, resp);
        let newTxsArray = this.foundTxs.concat(parsedNewTxs);
        this.foundTxs = this.parseTransactions(newTxsArray);
        if(this.foundAddress.totalTxs + this.foundAddress.unconfirmedTotalTxs < this.foundTxs.length) {
          this.foundAddress.totalTxs = this.foundTxs.length % 25 === 0 ? this.foundTxs.length + 1 : this.foundTxs.length;
        }
        this.$refs.card.fetchedTransactions = this.foundTxs;
        this.$refs.card.$refs.table.txs = this.foundTxs;
        this.$refs.card.$refs.table.isPaginationLoading = false;
      });
    },
    fetchTransactions(callback = null) {
      if(!!this?.$refs?.card && !this.$refs.card.isLoading && !this.$refs.card.fetchedTransactions && !this.shouldLoadForm) {
        this.$refs.card.isLoading = true;
        this.shouldLoadForm = true;
        this.$refs.card.resetTxData();
        API.init()[this.currCrypto.addresTxsFn](this.foundAddress.address, (resp) => {
          let newTxsArray = this.currCrypto.parseAddressTxsRespData(this.foundAddress.address, resp);
          this.foundTxs = this.parseTransactions(newTxsArray);
          if(this.foundAddress.totalTxs === 0 && this.foundAddress.unconfirmedTotalTxs === 0) {
            this.foundAddress.totalTxs = !!this.foundTxs.length && this.foundTxs.length % 25 === 0 ? this.foundTxs.length + 1 : this.foundTxs.length;
          }

          this.shouldLoadForm = false;
          if(this.$refs.card) { this.$refs.card.importTransactions(this.foundTxs); }
          if(callback) { callback(); }
        });
      }
    },
    parseTransactions(txs) {
      let newTxArray = [];
      let sameTxs = 0;
      txs.forEach(tx=>{
        let alreadyFoundTx = newTxArray.find(sameTx => { return sameTx.txId === tx.txId });
        if(alreadyFoundTx) {
          newTxArray[newTxArray.indexOf(alreadyFoundTx)].value += tx.value;
          sameTxs += 1;
        } else {
          newTxArray.push(tx);
        }
      });
      this.foundAddress.totalTxs -= sameTxs;
      return newTxArray;
    },
    clickedFetchTransactions() {
      this.fetchTransactions(()=>{
        this.$refs.card.openTransactionTable();
      });
    },
    fetchPrices() {
      let self = this;
      API.init().getUsdPrice(self.currCrypto.coinMarketCapId, (resp1) => {
        this.parseCoinMarketCapResponse(resp1, 'usd');
        setTimeout(() => {
          API.init().getEurPrice(self.currCrypto.coinMarketCapId, (resp) => {
            this.parseCoinMarketCapResponse(resp, 'eur');
            this.refreshLastPriceUpdate();
          });
        }, 2000);
      });
    },
    parseCoinMarketCapResponse(resp, currency) {
      if (resp?.body?.data !== undefined) {
        let newKey = Object.keys(resp.body.data)[0];
        let respPrice = resp.body.data[newKey].quote?.[currency.toUpperCase()]?.price;
        if (respPrice !== undefined) {
          this.currCrypto[currency + 'Price'] = respPrice;
          if (Utils.acceptedCookies()) {
            window.localStorage.setItem(this.currCrypto.coinMarketCapId.toString() + currency.toUpperCase() + "price", respPrice);
          }
        }
      }
    },
    refreshLastPriceUpdate() {
      let lastUpdate = new Date().setMinutes(
          new Date().getMinutes() + this.minutesUpdatePrice
      );
      if (Utils.acceptedCookies()) {
        window.localStorage.setItem(
            this.currCrypto.coinMarketCapId + "price_update",
            JSON.stringify(lastUpdate)
        );
      }
    },
    clearError() { this.errorForm = false; },
    openForm() { if(!this.$refs.card.isLoading) { this.activeForm = true; } },
    switchFiat() {
      this.isConventional = !this.isConventional;
      Utils.setStorageItem('isConventional', this.isConventional ? '1' : '0');
      Firebase.init().updateConventional(this.isConventional);
    },
    switchUnit() {
      this.isUsd = !this.isUsd;
      Utils.setStorageItem('isUsd', this.isUsd ? '1' : '0');
      Firebase.init().updateUsd(this.isUsd);
    },
    deleteAddressFromHistory(address) {
      this.$emit('deleteAddressFromHistory', address);
    },
  },
  watch: {
    address: function(newVal, oldVal) {
      if(!!oldVal && !!newVal) {
        this.foundAddress = {
          address: "",
              balance: 0,
              totalTxs: 0,
              unconfirmedBalance: 0,
              unconfirmedTotalTxs: 0
        },
        Utils.scrollToElem(this.$refs['settings-buttons'], 150);
        this.parseInputAddress(this.$props.address);
      }
    }
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth", });
    let storageIsUsd = Utils.getStorageItem('isUsd');
    let storageIsConventional = Utils.getStorageItem('isConventional');
    if (storageIsUsd) {
      this.isUsd = storageIsUsd === '1';
    }
    if (storageIsConventional) {
      this.isConventional = storageIsConventional === '1';
    }
    if (Utils.acceptedCookies()) {
      let currEur = window.localStorage.getItem(
          this.currCrypto.coinMarketCapId.toString() + "EURprice"
      );
      let currUsd = window.localStorage.getItem(
          this.currCrypto.coinMarketCapId.toString() + "USDprice"
      );
      if (currEur) {
        this.currCrypto.eurPrice = Number(currEur);
      }
      if (currUsd) {
        this.currCrypto.usdPrice = Number(currUsd);
      }
    }
    let lastUpdate = Utils.acceptedCookies()
        ? window.localStorage.getItem(
            this.currCrypto.coinMarketCapId + "price_update"
        )
        : null;
    if (!lastUpdate || new Date(JSON.parse(lastUpdate)) < new Date()) {
      setTimeout(() => {
        this.fetchPrices();
      }, 2000);
    }
    if(this.$props.address) {
      this.parseInputAddress(this.$props.address);
    }
    if(!!window.VanillaTilt) {
      window.VanillaTilt.init(document.querySelectorAll('.tilt-card-explorer'));
    }
  },
  created() {
    document.title = this.metadata.title;
    document.querySelector('meta[name="description"]').setAttribute("content", this.metadata.description);
  },
}


</script>
<style lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";
.inputs-amount, .outputs-amount {
  @include fitContent;
  padding: 0 5px;
  border-radius: 5px;
}
.inputs-amount{ background-color: rgba(#F44336, .6); }
.outputs-amount{ background-color: rgba(#4CAF50, .6); }
</style>
