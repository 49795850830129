<template>
  <div class="col-12 px-2">
    <v-list-item
        elevation="1"
        ref="list-item"
        @click="clickListItem"
        :active="detailsShown"
        style="backdrop-filter: blur(10px)"
        class="no-select"
        :prepend-icon="retrievedAddress ? icons['numeric'+retrievedAddress.pattern.length+'Box'] : ''"
        active-color="orange-darken-2">
      <v-list-item-header>
        <v-list-item-title class="word-wrap">{{ retrievedAddress ? retrievedAddress.pattern : '' }}</v-list-item-title>
        <v-list-item-subtitle class="word-wrap"><small>{{ item.address }}</small></v-list-item-subtitle>
      </v-list-item-header>
    </v-list-item>
    <div style="backdrop-filter: blur(10px)">
      <transition name="gain-height">
        <v-list-item border="1" class="no-select mb-5" v-show="detailsShown">
          <div class="row pt-2 pb-5">
            <div class="col-12">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Address</small></p>
              <div>
                <Tooltip text="Click to copy">
                  <p @click="clickToCopy" class="pointer monospace truncate">{{ item.address }}</p>
                </Tooltip>
              </div>
            </div>
            <div v-if="retrievedAddress" class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Pattern</small></p>
              <p class="truncate">{{ retrievedAddress.pattern }}</p>
            </div>
            <div v-if="retrievedAddress" class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Pattern length</small></p>
              <p class="truncate">{{ retrievedAddress.pattern.length }}</p>
            </div>
            <div class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Purchase date</small></p>
              <p class="truncate">{{ new Date(item.timestamp.seconds*1000).toLocaleString() }}</p>
            </div>
            <div class="col-12 pt-3">
              <p style="line-height: 8px" class="opacity-40 text-overline"><small>Order ID</small></p>
              <p class="truncate">{{ item.order_id }}</p>
            </div>
            <div class="col-12 justify-center align-end pt-8">
              <div>
                <v-btn @click="showPrivateKey" :disabled="isLoadingPrivKey" min-width="200" color="transparent" flat text-color="orange-darken-2">{{ showingPrivKey ? 'Hide' : 'Show' }} private key <v-icon class="ml-3" size="small">{{ icons.key }}</v-icon></v-btn>
              </div>
              <v-progress-linear
                  v-show="isLoadingPrivKey"
                  class="opacity-60 mt-8"
                  height="1"
                  indeterminate
              ></v-progress-linear>
            </div>
            <transition name="gain-height">
              <div v-if="retrievedPrivateKey && showingPrivKey" class="col-12 pt-3">
                <p style="line-height: 8px" class="mb-2 opacity-40 text-overline"><small>Private key</small></p>
                <div class="col-12">
                  <v-text-field
                      density="compact"
                      class="shrink"
                      v-model="retrievedPrivateKey"
                      :type="showPrivKeyChars ? 'text' : 'password'"
                      readonly
                      :label="privKeyLabel"
                      style="min-width: 300px; max-width: 500px"
                  >
                    <template v-slot:append>
                      <v-btn title="Copy" :color="copyColor" @click="copyPrivKey" size="small" :icon="icons.copy" flat></v-btn>
                      <v-btn :title="showPrivKeyChars ? 'Hide text' : 'Show text'" color="transparent" @click="showPrivKeyChars = !showPrivKeyChars" size="small" :icon="showPrivKeyChars ? icons.eyeOff : icons.eye" flat></v-btn>
                    </template>
                  </v-text-field>
                </div>
              </div>
            </transition>
          </div>
        </v-list-item>
      </transition>
    </div>
  </div>
</template>

<script>
import API from "@/assets/js/API";
import Firebase from "@/assets/js/Firebase";
import Tooltip from "@/components/Tooltip";
import Utils from "@/assets/js/Utils";
import {mdiKey, mdiContentCopy, mdiEye, mdiEyeOff, mdiNumeric0Box, mdiNumeric1Box, mdiNumeric2Box, mdiNumeric3Box, mdiNumeric4Box, mdiNumeric5Box, mdiNumeric6Box, mdiNumeric7Box, mdiNumeric8Box, mdiNumeric9Box} from "@mdi/js";

export default {
  name: "PurchasedAddressListItem",
  components: { Tooltip },
  data() {
    return{
      icons: {
        key: mdiKey,
        copy: mdiContentCopy,
        eye: mdiEye,
        eyeOff: mdiEyeOff,
        numeric0Box: mdiNumeric0Box,
        numeric1Box: mdiNumeric1Box,
        numeric2Box: mdiNumeric2Box,
        numeric3Box: mdiNumeric3Box,
        numeric4Box: mdiNumeric4Box,
        numeric5Box: mdiNumeric5Box,
        numeric6Box: mdiNumeric6Box,
        numeric7Box: mdiNumeric7Box,
        numeric8Box: mdiNumeric8Box,
        numeric9Box: mdiNumeric9Box,
      },
      detailsShown: false,
      currDate: new Date(),
      retrievedAddress: null,
      retrievedPrivateKey: null,
      showingPrivKey: false,
      isLoadingPrivKey: false,
      showPrivKeyChars: false,
      copyColor: 'transparent',
      privKeyLabel: '',
    }
  },
  props: {
    item: Object,
  },
  methods: {
    copyPrivKey() {
      navigator.clipboard.writeText(this.retrievedPrivateKey);
      this.privKeyLabel = 'Copied!';
      this.copyColor = 'orange';
      setTimeout(() => {
        this.copyColor = 'transparent';
      }, 50);
      setTimeout(() => {
        this.privKeyLabel = '';
      }, 1000);
    },
    clickToCopy(e) {
      let tooltip = e.currentTarget.parentElement.querySelector('.tooltiptext');
      let textToCopy = !!tooltip && !!tooltip?.dataset?.textToCopy ? tooltip.dataset.textToCopy : e.currentTarget.innerText;
      navigator.clipboard.writeText(textToCopy);
      if (tooltip) {
        let target = e.currentTarget;
        target.classList.add('text-orange', 'no-pointer-events');
        let oldText = tooltip.innerText;
        tooltip.innerText = 'Copied!';
        setTimeout(() => {
          target.classList.remove('text-orange');
        }, 50);
        setTimeout(() => {
          tooltip.innerText = oldText;
          target.classList.remove('no-pointer-events');
        }, 1000);
      }
    },
    setCurrDate() {
      this.currDate = new Date();
    },
    showPrivateKey() {
      this.showPrivKeyChars = false;
      if(!this.showingPrivKey) {
        if(!this.retrievedPrivateKey) {
          this.isLoadingPrivKey = true;
          API.init().getPrivateKey(this.$props.item.address, Firebase.init().uid, this.$props.item.chargeId, r=>{
            this.isLoadingPrivKey = false;
            this.retrievedPrivateKey = !!r && !!r?.body ? r.body : null;
            this.showingPrivKey = true;
          });
        } else {
          this.showingPrivKey = true;
        }
      } else {
        this.showingPrivKey = false;
      }
    },
    clickListItem() {
      this.detailsShown = !this.detailsShown;
      if(this.detailsShown) {
        Utils.scrollToElem(this.$refs['list-item'].$el, 300);
      }
    },
  },
  computed: {

  },
  mounted() {
    setInterval(this.setCurrDate, 1000);
    Firebase.init().getAddress(this.$props.item.address, addressResp => {
      this.retrievedAddress = addressResp;
    });
  }
}
</script>

<style lang="scss" scoped>

.v-list--density-default .v-list-item--prepend, .v-list--density-default .v-list-group--prepend > .v-list-group__header {
  -webkit-padding-start: 16px !important;
  padding-inline-start: 16px !important;
}

</style>