<template>
    <v-expand-transition>
      <v-card color="transparent" v-show="isShown && parseAddresses && parseAddresses.length">
        <v-list max-height="120" class="pa-0" density="compact">
          <p class="text-caption opacity-40">Suggested</p>
          <v-list-item v-for="addressN of parseAddresses" :key="addressN.address" @click="clickAutocompleteAddress($event, addressN)" :class="isLoading?'no-pointer-events':'pointer'" class="history-element py-1" density="compact">
            <div class="col-12">
              <div class="row">
                <v-badge
                    v-if="addressN.favourite"
                    location="bottom-left"
                    :text-color="addressN.color ? addressN.color : 'orange'"
                    color="transparent"
                    :icon="icons.star"
                    offset-x="10px"
                    :offset-y="addressN.favourite && !addressN.name ? '8px' : '0'"
                >
                  <div style="width: 20px; height: 20px"></div>
                </v-badge>
                <p class="history-element-address truncate text-body-2" :style="'max-width: '+(addressN.favourite ? 'calc(100% - 50px)' : 'calc(100% - 30px)')">{{ addressN.address }}</p>
                <v-btn v-if="!addressN.favourite" @click.stop="clickDeleteAddress(addressN)" class="delete-icon" size="compact" flat text-color="red-darken-2" style="margin-left: 8px" :class="addressN.name ? 'mt-auto' : 'my-auto'">
                  <v-icon title="Delete from history" size="compact">{{ icons.close }}</v-icon>
                </v-btn>
              </div>
              <div v-if="addressN.favourite && addressN.name && addressN.name.length > 0" class="row pl-6" style="max-width: 100%"><p class="text-caption truncate opacity-40">{{ addressN.name }}</p></div>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-expand-transition>
</template>

<script>

import Utils from "@/assets/js/Utils";
import { mdiStar, mdiClose } from "@mdi/js";

export default {
  name: "AutocompleteDropdown",
  data: () => ({
      icons: {
        star: mdiStar,
        close: mdiClose,
      },
  }),
  props: {
    isShown: { type: Boolean },
    inputValue: { type: String },
    isLoading: { type: Boolean },
  },
  computed: {
    currentCrypto() { return this.$route.path.substr(1,3) },
    addressesHistory() { return window.app?.userData?.addressesHistory ? window.app.userData.addressesHistory : []; },
    favourites() { return window.app?.userData?.favourites ? window.app.userData.favourites : {}; },
    parseAddresses() {
      let returningArray = [];
      this.addressesHistory.forEach(address=>{
        if(address.crypto === this.currentCrypto) {
          let newObj = { address: address.address };
          if(this.favourites) {
            let foundAddress = this.favourites[address.address];
            if(!!foundAddress) {
              newObj = Utils.deepObjCopy(foundAddress);
              newObj.address = address.address;
              newObj.favourite = true;
            }
          }
          if(!returningArray.find(addr=>addr.address===newObj.address)) { returningArray.push(newObj); }
        }
      });
      if(this.favourites) {
        Object.keys(this.favourites).forEach(fav => {
          let foundAddress = this.addressesHistory.find(address => { return address.address === fav; });
          if(!foundAddress && this.favourites[fav].crypto === this.currentCrypto) {
            let newObj = Utils.deepObjCopy(this.favourites[fav]);
            newObj.address = fav;
            newObj.favourite = true;
            if(!returningArray.find(addr=>addr.address===newObj.address)) { returningArray.push(newObj); }
          }
        });
      }
      returningArray.sort((a, b)=>{ return a.address < b.address ? -1 : 1; });
      returningArray.sort((a)=>{ return a.favourite ? -1 : 1; });
      if(this.inputValue) {
        returningArray = returningArray.filter(a => {
          return (a.address &&
                  (a.address.toLowerCase().startsWith(this.inputValue.toLowerCase()) ||
                      (a.address.toLowerCase().search(this.inputValue.toLowerCase()) > -1)))
              || (a.name && (a.name.toLowerCase().startsWith(this.inputValue.toLowerCase()) ||
                  a.name.toLowerCase().search(this.inputValue.toLowerCase()) > -1))
        });
      }
      return returningArray;
    },
  },
  methods: {
    clickAutocompleteAddress(e, addressN) {
      if(!e.target.classList.contains('delete-icon')) {
        this.$emit('clickedAddress', addressN.address);
      }
    },
    clickDeleteAddress(address) {
      this.$emit('clickedDeleteAddress', address);
    },
  },
  mounted() { }
}
</script>

<style lang="scss" scoped>

.history-element {
  opacity: .6;
  &:hover {
    opacity: 1;
  }
}

</style>