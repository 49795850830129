<template>
  <div class="col-12">
    <div class="row pb-20">
      <div class="col-12 pt-20 centered">
        <p class="text-h3 text-align-center opacity-80">Explore the <span class="orange monospace">blockchain</span></p>
        <p class="text-subtitle-2 text-align-center mx-auto opacity-80">A powerful tool for tracking addresses, accounts and cash flows on the bitcoin network</p>
        <p class="text-body-1 text-align-center my-10 mx-auto">
          <i class="opacity-80">
            Bitcoin is often perceived as an anonymous payment network. But in reality, it is probably <span class="text-orange">the most transparent payment network </span> in the world.
          </i>
        </p>
        <p class="text-h4 transition-right">What is Mezcla.app?</p>
        <div class="row pt-5 position-relative">
          <div class="col-12 coin-transition">
            <div class="row align-center flex-end">
              <div class="col-6"></div>
              <div class="col-6">
                <v-icon style="height: 120%" color="orange" size="200">{{icons.bitcoin}}</v-icon>
              </div>
            </div>
            <p class="text-body-1 text-justify mx-auto">
              This platform is a web Bitcoin playground. It is currently in beta testing.
              <br>
              A general knowledge in Bitcoin fundamentals is required in order to navigate this website. If you are a newbie, feel curious or want to dig more into the rabbit hole, head to the <router-link class="text-orange no-line" to="/intro">introduction</router-link>.
              <br><br>
            </p>
          </div>
        </div>
        <div class="row flex-start text-align-left">
          <div class="col-12">
            <div class="row centered py-10">
              <div
                  v-for="feature in featuresList"
                  :class="feature.empty ? 'hide-mobile' : ''"
                  class="col-4 mobile-12 align-center flex-start mb-5"
              >
                <FeaturesCard
                    v-if="!feature.empty"
                    :link="feature.link"
                    :card-title="feature.cardTitle"
                    :main-icon="feature.mainIcon"
                    :phrases="feature.phrases"
                ></FeaturesCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 medium-12 mb-20">
        <div>
          <div style="max-width: 344px" class="glass tilt-card no-select pointer mx-auto m-20 no" data-tilt>
            <div class="glass-content">
              <v-card
                  @click="$router.push('/btc')"
                  v-ripple
                  color="transparent"
              >
                <v-card-text class="bg-orange-custom opacity-100">
                  <p class="appear-up">Cryptocurrency</p>
                  <p class="text-h4">Bitcoin</p>
                  <p class="appear text-overline opacity-60"><small>Find and track addresses</small></p>
                </v-card-text>
                <v-divider thickness="5"></v-divider>
                <v-card-text style="min-height: 180px;">
                  <p class="text-body-1 text-align-center mt-5 mx-auto">
                    If you want to know how much money is stored in an account and view its complete history of transactions, head to the <router-link class="text-orange no-line" to="/btc">Explorer section</router-link>.
                    <br>
                  </p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn class="appear" color="transparent" text>Go to explorer</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 medium-12 mb-20">
        <div>
          <div style="max-width: 344px" class="glass tilt-card no-select pointer mx-auto m-20" data-tilt>
            <div class="glass-content">
              <v-card
                  @click="$router.push('/shop')"
                  v-ripple
                  color="transparent"
              >
                <v-card-text class="bg-purple-custom opacity-100">
                  <p class="appear-up">Lightning Network <v-icon>{{ icons.lightning }}</v-icon> payments only</p>
                  <p class="text-h4">Vanity addresses</p>
                  <p class="text-overline opacity-60 appear"><small>Starting from 5000 sats</small></p>
                </v-card-text>
                <v-divider thickness="5"></v-divider>
                <v-card-text>
                  <p class="text-body-1 text-align-center mt-5 mx-auto" style="max-width: 400px">
                    Have a look at this list of hardly obtained <router-link class="text-orange no-line" to="/shop"><i>Vanity Addresses</i></router-link>.
                    <br>
                    You may find a personalized address which fits your character: there are 20k+ patterns to search within.
                  </p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="transparent" class="appear" text>Go to shop</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pt-100 align-end">
        <div>
          <div style="max-width: 344px; min-width: 250px;" class="glass orange tilt-card no-select pointer mx-auto m-20" data-tilt>
            <div class="glass-content">
              <v-card
                  @click="$router.push('/verify')"
                  v-ripple
                  color="transparent"
              >
                <v-card-actions>
                  <v-btn color="transparent" text class="opacity-60 appear-up">Signature verifier</v-btn>
                  <v-spacer></v-spacer>
                  <v-icon class="appear-right">{{ icons.arrowRight }}</v-icon>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/js/vanillaTitlt";
import FeaturesCard from "@/components/FeaturesCard";
import {
  mdiArrowRight,
  mdiLightningBolt,
  mdiLaunch,
  mdiBitcoin,
  mdiStar,
  mdiCircleSmall,
  mdiMagnify,
  mdiShopping,
  mdiTools
} from "@mdi/js";

export default {
  name: 'Home',
  components: { FeaturesCard },
  data() {
    return {
      icons: {
        arrowRight: mdiArrowRight,
        lightning: mdiLightningBolt,
        link: mdiLaunch,
        bitcoin: mdiBitcoin,
        star: mdiStar,
        circle: mdiCircleSmall,
        search: mdiMagnify,
        shop: mdiShopping,
        tools: mdiTools,
      },
      metadata: {
        title: "Explore the blockchain",
        description: "Search for bitcoin cash flows and find your personalized address!",
      },
      featuresList: [
        {
          mainIcon: mdiShopping,
          cardTitle: "Shop",
          phrases: ['Find the address that fits your wanted pattern', 'Pay with lightning network'],
          link: '/shop',
        },
        {
          mainIcon: mdiMagnify,
          cardTitle: "Explorer",
          phrases: ['Search addresses, view balance, browse transactions', 'Switch between EUR, USD, sats and BTC views'],
          link: '/btc',
        },
        {empty: true},
        {empty: true},
        {
          mainIcon: mdiStar,
          cardTitle: "Favourites",
          phrases: ['Add to favourites for a better user experience', 'Set nicknames and colors'],
          link: '/favourites',
        },
        {
          mainIcon: mdiTools,
          cardTitle: "Utility",
          phrases: ['Verify bitcoin signed messages'],
          link: '/verify',
        },
      ],
    }
  },
  methods: {

  },
  mounted() {
    VanillaTilt.init(document.querySelectorAll(".tilt-card"));
  },
  created() {
    document.title = this.metadata.title;
    document.querySelector('meta[name="description"]').setAttribute("content", this.metadata.description);
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variables.scss";

@mixin blurred {
  background-color: transparent;
  backdrop-filter: blur(10px);
}
@mixin glassedContainer {
  @include blurred;
  border-radius: 15px;
  border-top: 1px solid rgba(var(--v-theme-on-surface), 0.5);
  border-left: 1px solid rgba(var(--v-theme-on-surface), 0.5);
}
.back-blurred { @include blurred; }
.glassed {
  @include glassedContainer;
}
.glass {
  @include glassedContainer;
  &.purple {
    background: rgba(#E040FB, 0.1);
    &:hover { background: rgba(#E040FB, 0.4); }
  }
  &.orange {
    background: rgba(#FF9800, 0.1);
    &:hover { background: rgba(#FF9800, 0.4); }
  }
  &.deep-purple {
    background: rgba(#4A148C, 0.1);
    &:hover { background: rgba(#4A148C, 0.4); }
  }
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.glass .glass-content {
  width: 100%;
  .appear, .appear-up, .appear-right {
    opacity: .3;
  }
  /*.appear { transform: translateY(100px); }
  .appear-right { transform: translateX(100px); }
  .appear-up { transform: translateY(-100px); }*/
  @media all and (max-width: 1080px){
    .appear, .appear-up, .appear-right {
      /*transform: translateY(0px);*/
      opacity: 1;
    }
  }
}

@media all and (min-width: 1081px){
  .glass:hover .glass-content {
    .appear, .appear-up, .appear-right {
      transition: 0.5s $transition;
      animation-duration: 0.2s;
      animation-timing-function: $transition;
      /*transform: translateY(0px);*/
      opacity: 1;
    }
    .appear { animation-name: appear; }
    .appear-right { animation-name: appear-right; }
    .appear-up { animation-name: appear-up; }
  }
}

.bg-orange-custom{ background-color: rgba(#FF9800, .6); }
.bg-purple-custom{ background-color: rgba(#9C27B0, .6); }

.coin-transition {
  transition: padding .5s ease;
  padding: 0 20px;
  .row {
    opacity: 20%;
    transition: width .5s ease, opacity .5s ease;
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    width: 200px;
    transform: translateX(-50%);
    .col-6 {
      overflow:hidden;
      height: 100%;

      i {
        transition: transform .5s ease;
        top:-10%;
        transform: translateX(-70%) rotate(-70deg);
      }
    }
  }
  &:hover {
    padding: 0 30px;
    .row {
      opacity: 30%;
      width: 400px;
      .col-6 i {
        transform: translateX(0) rotate(0deg);
      }
    }
  }
}

@keyframes appear-right {
  0% { transform: translateX(100px); }
  100% { transform: translateX(0); }
}
@keyframes appear-up {
  0% { transform: translateY(-100px); }
  100% { transform: translateY(0); }
}
@keyframes appear {
  0% { transform: translateY(100px); }
  100% { transform: translateY(0); }
}
</style>